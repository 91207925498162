.navigation {
  background-color: #1b1d27;
  height: 100%;
  width: max-content;

  &__content {
    padding: 40px 20px;
    background-color: #1b1d27;
    position: sticky;
    top: 0;
    z-index: 10;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 28px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 12px;

    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
    color: #454951;

    &_active {
      color: white;
    }
  }

  &__icon {
    width: 30px;
    padding: 22px 0 0 0;
  }
}
