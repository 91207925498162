.hero {
  overflow: hidden;
  padding: 100px 0px 266px 0px;
  position: relative;
  background: linear-gradient(
    125.75deg,
    #9021ff 5%,
    #7637e0 23%,
    #462195 44%,
    #291468 69%,
    #191031 100%
  );
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 40px;

  @media (max-width: 768px) {
    position: relative;
    padding: 80px 0px 290px 0px;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    max-width: 1200px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 768px) {
      padding: 0px 20px;
      width: auto;
    }
  }

  &__titleBlock {
    position: relative;
    z-index: 5;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }

  &__title {
    color: #ffffff;
    font-size: 120px;
    font-weight: 800;
    line-height: 144px;
    text-align: left;
    font-family: DrukTextWide;
    text-transform: uppercase;
    text-shadow: rgba(255, 255, 255, 0.45) 0px 0px 8px;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 50px;
    }
  }

  &__subtitle {
    max-width: 700px;
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 27.28px;
    }
  }

  &__btnContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 24px;

    @media (max-width: 950px) {
      button {
        &:first-child {
          order: 3;
        }
      }
    }

    @media (max-width: 580px) {
      flex-direction: column;
    }
  }

  &__cards {
    box-sizing: border-box;
    position: relative;
    z-index: 5;
    max-width: 650px;
    width: 100%;

    display: flex;
    align-items: stretch;
    justify-content: center;
    gap: 24px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      padding: 0px 20px;
      // gap: 16px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__coin {
    &_left {
      height: 500px;
      width: 500px;
      position: absolute;
      top: 15%;
      left: 0px;

      img {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 1200px) {
        top: 50%;
        left: -150px;
      }

      @media (max-width: 845px) {
        height: 400px;
        top: 60px;
        left: -260px;
      }
    }

    &_right {
      height: 250px;
      width: 250px;
      position: absolute;
      top: 25%;
      right: 100px;

      img {
        width: 100%;
        height: 100%;
      }

      @media (max-width: 1200px) {
        height: 250px;
        top: 10%;
        right: -100px;
      }

      @media (max-width: 845px) {
        height: 250px;
        top: 75%;
        right: -100px;
      }
    }
  }
}
