$primaryColor: #886cff;

.Viewing-player {
  width: 100%;
  display: inline-flex;

  span {
    color: white;
    font-weight: 800;
  }

  .Player-logs {
    width: 50%;

    .Inventory-actions {
      ul {
        width: 100%;
        list-style: none;
        display: inline-flex;
      }
      ul:nth-child(1) {
        margin-left: 15px;
      }
      ul:nth-child(2) {
        margin-top: 15px;
        justify-content: space-around;
      }

      button {
        margin-left: 5px;
      }
    }

    input,
    select {
      margin: 5px;
      height: 40px;
      color: white;
      background: none;
      border: none;
    }

    input:focus,
    select:focus {
      outline: none;
    }
  }
}

.logs {
  width: calc(100% - 20px);
  padding: 10px;
  overflow-x: scroll;
  ul {
    width: 100%;
    list-style: none;
    display: inline-flex;
    justify-content: space-around;
    color: #acacac;
    background: #121418;
    border: 1px solid #2a2e38;
    border-radius: 5px;
    padding: 10px 0;
    margin-top: 3px;

    li {
      padding: 10px 5px;
      width: calc(100% - 200px);
    }
    li:first-child {
      width: 200px;
    }
    li:nth-child(2) {
      font-weight: 800;
      color: white;
      white-space: nowrap;
    }
  }
}

.Player-overview {
  width: 50%;
  border-right: 1px solid $primaryColor;

  ul {
    list-style: none;
    display: inline-flex;
    width: calc(100% - 40px);
    justify-content: space-between;
    padding: 0 20px 0 20px;
    color: white;
    margin-bottom: 10px;

    li:nth-child(odd) {
      font-weight: 800;
    }
  }
}

.Player-overview-full {
  width: 100%;
  border-right: none;
}

.Blacklist-Challenge {
  width: 100%;
  height: 500px;
  overflow-y: scroll;
  overflow-x: hidden;

  ul {
    width: 100%;

    li {
      width: 100%;
      background: #121418;
      border: 1px solid #2a2e38;
      border-radius: 5px;
      padding: 10px 0;
      color: #acacac;
      font-weight: 800;
      margin-top: 3px;
    }
  }
}

.Blacklist {
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;

  ul {
    width: 100%;

    li {
      width: 100%;
      background: #121418;
      border: 1px solid #2a2e38;
      border-radius: 5px;
      padding: 10px 0;
      color: #acacac;
      font-weight: 800;
      margin-top: 3px;
    }
  }
}

.PromoTimerContainer {
  height: 100px;
  margin: 0 0 25px 10px;
  display: inline-flex;
  border-radius: 5px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  justify-content: center;

  p {
    color: black !important;
  }

  ul {
    list-style: none;
    margin: 0px;
    font-family:
      'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
      sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5;
    //border: 2px solid #333845;
    height: 100px;
    width: 100px;
    border-radius: 0.5rem;
    display: flex;
    flex-direction: column;
    -moz-box-pack: center;
    justify-content: center;
    -moz-box-align: center;
    align-items: center;
    background: #404558 !important;
    color: white;
    margin: 0 10px;

    li {
      text-transform: uppercase;
      font-size: 12px;
    }

    .TimeValue {
      color: $primaryColor;
      font-weight: 800;
      font-size: 42px;
    }
  }

  span {
    font-size: 21px;
  }
}

.Games-search-result {
  height: 350px;
  width: 585px;
  overflow-y: scroll;
  overflow-x: hidden;

  .Result-entry {
    background: #121418;
    border: 1px solid #2a2e38;
    border-radius: 5px;
    padding: 5px 0;
    color: #acacac;
    cursor: pointer;

    ul {
      list-style: none;
      display: inline-flex;
      width: 100%;
      justify-content: left;
      text-align: left;

      li:nth-child(1) {
        width: 50px;
        span {
          padding-left: 10px;
        }
      }
      li:nth-child(2) {
        width: 50px;
      }
      li:nth-child(3) {
        width: calc(100% - 100px);
      }
    }
  }

  .Result-entry:hover {
    background: #1d2026;
  }
}

.Dashboard {
  display: flex;
  flex-direction: column;
  width: 100%;

  .Dashboard-Metrics {
    display: inline-flex;
    width: 100%;
    gap: 20px;
    flex-wrap: wrap;

    button {
      margin: 30px 10px 0 0;
      height: 50px !important;
    }
  }
  .Dashboard-Pending {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: left;

    span {
      width: 100%;
      text-align: left;
      padding: 10px 20px;
      color: white;
    }
  }
}
.MetricCard {
  background-color: #1f1f1f;
  background: #121418;
  border: 1px solid #2a2e38;
  border-radius: 5px;
  padding: 5px 0;
  color: #acacac;
  width: 220px;
  height: 80px;
  display: flex;
  justify-content: left;
  flex-direction: column;
  font-family:
    Noto Sans,
    Inter,
    Poppins,
    Montserrat,
    Helvetica,
    Arial,
    sans-serif;

  h6,
  h3 {
    width: 100%;
    text-align: left;
    padding-left: 15px;
    font-size: 16px;
    text-transform: uppercase;
  }

  h6 {
    font-size: 42px;
    color: white;
  }
}

.Breakdown {
  width: 350px;
  display: flex;
  border: 1px solid $primaryColor;
  .Breakdown-title {
    width: 40%;
  }
  .Viewership {
    width: 60%;

    li {
      width: 100%;
      white-space: nowrap;
      margin-left: 10px;
    }
  }
}
