@import 'src/assets/CSS/variables.scss';

.page {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 50px;

  &__title {
    color: white;
    font-weight: 900;
    font-size: 42px;
    text-transform: uppercase;
    text-align: center;
  }

  span {
    color: white;
    font-weight: 800;
  }

  &__editor {
    ul {
      list-style: none;
      display: inline-flex;
      width: calc(100% - 40px);
      justify-content: space-between;
      padding: 0 20px 0 20px;
      color: white;
      margin-bottom: 10px;

      li:nth-child(odd) {
        font-weight: 800;
      }
    }

    select {
      background: none;
      border: none;
      color: white;
    }
  }

  &__tabs {
  }

  &__btns {
    display: flex;
    justify-content: end;
    align-items: center;
    gap: 20px;
  }

  &__uploader {
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: linear-gradient(104.01deg, #5b18a5 0%, #831ee9 100%);
    border: 1px solid #5b18a5;
    border-radius: 40px;
    padding: 10px 16px;
    color: white;
    box-shadow: none;
    font-size: 18px;
    font-weight: 800;
    line-height: 24.55px;
    text-align: center;
    border-radius: 6px;
  }
}

.tabs {
  &__list {
    background-color: #0000;
    border-bottom: 1px solid #3333454d;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
  }

  &__tab {
    outline: none;
    align-items: center;
    color: #444;
    cursor: pointer;
    display: inline-flex;
    float: left;
    font-size: 14px;
    font-weight: 600;
    height: 44px;
    justify-content: center;
    list-style: none;
    position: relative;
    touch-action: manipulation;
    transition:
      background 0.3s ease 0s,
      opacity 0.3s ease 0s,
      transform 0.1s ease 0s;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap;
    width: 100px;

    &_active {
      border-width: 1px 1px 0;
      border-bottom: 2px solid #886cff !important;
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      color: #886cff;
    }
  }

  &__panel {
    h4 {
      color: white;
      font-size: 50px;
      font-weight: 900;
      text-align: center;
    }

    &__header {
      display: flex;
      flex-direction: column;
      gap: 5px;
      margin-bottom: 20px;

      button {
        align-self: self-end;
      }
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  padding: 40px 16px;

  &__content {
    display: flex;
    border-radius: 25px;
    overflow: hidden;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    color: white;
    text-align: center;
    flex: 1;

    background: #2d2f37;

    border-radius: 0px;

    &:first-child {
      border-radius: 25px 0px 0px 25px;
    }

    &:last-child {
      border-radius: 0px 25px 25px 0px;
    }

    @media (max-width: 768px) {
      &:first-child {
        border-radius: 25px;
      }

      &:last-child {
        border-radius: 25px;
      }
    }
  }

  &__active {
    background: linear-gradient(104.01deg, #5b18a5 0%, #831ee9 100%);
  }

  // @media (max-width: 768px) {
  //   display: none;
  // }
}
