@import 'src/assets/CSS/variables.scss';

.raffles {
  color: white;

  &__table {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__head {
    padding: 80px 0px 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: white;
  }

  &__heading {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;

    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: $secondaryGray;
    }
  }

  &__headerList {
    padding: 24px;

    display: flex;
    justify-content: space-between;
    gap: 50px;

    li {
      display: flex;
      justify-content: start;
    }
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__item {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__row {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;

    button {
      width: 100%;
      max-width: 200px;
    }
  }
}
