.hero {
  overflow: hidden;
  padding: 266px 0px 266px 0px;
  position: static;
  background:
    url('../../../assets/images/giveaways/giveawayBg.png') center no-repeat,
    linear-gradient(
      125.75deg,
      #9021ff 5%,
      #7637e0 23%,
      #462195 44%,
      #291468 69%,
      #191031 100%
    );

  @media (max-width: 500px) {
    background:
      url('../../../assets/images/giveaways/giveawayBgMobile.png') center
        no-repeat,
      linear-gradient(
        125.75deg,
        #9021ff 5%,
        #7637e0 23%,
        #462195 44%,
        #291468 69%,
        #191031 100%
      );
  }

  @media (max-width: 768px) {
    position: relative;
    padding: 120px 0px 174px 0px;
  }

  &__container {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    max-width: 1200px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 768px) {
      justify-content: center;
      text-align: center;
      position: static;
    }
  }

  &__titleBlock {
    position: relative;
    z-index: 5;
  }

  &__title {
    margin-bottom: 16px;
    font-size: 120px;
    font-weight: 800;
    line-height: 144px;
    text-transform: uppercase;
    filter: drop-shadow(0px 0px 8px #ffffffcc);
    color: white;

    @media (max-width: 768px) {
      text-align: center;
      font-size: 62px;
      font-weight: 800;
      line-height: 74.4px;
      filter: none;
      text-transform: capitalize;
    }
  }

  &__divider {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;
    color: white;
    margin: 32px 0px 40px 0px;

    @media (max-width: 768px) {
      font-size: 20px;
      font-weight: 600;
      line-height: 27.28px;
      text-align: center;
    }
  }

  &__btnContainer {
    display: none;

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: start;
      align-items: center;
      gap: 12px;
    }
  }

  &__btn {
    max-width: 271px;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 16px;

    button {
      width: 100%;
    }
  }
}

.buttonGroup {
  display: flex;
  justify-content: center;
  max-width: 346px;
  margin: 0 auto;

  &__content {
    display: flex;
    border-radius: 25px;
    overflow: hidden;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    color: white;
    text-align: center;
    flex: 1;

    background: #2d2f37;

    &:first-child {
      border-radius: 25px 0px 0px 25px;
    }

    &:last-child {
      border-radius: 0px 25px 25px 0px;
    }

    @media (max-width: 768px) {
      &:first-child {
        border-radius: 25px;
      }

      &:last-child {
        border-radius: 25px;
      }
    }
  }

  &__active {
    background: linear-gradient(104.01deg, #5b18a5 0%, #831ee9 100%);
  }

  // @media (max-width: 768px) {
  //   display: none;
  // }
}
