@media (max-width: 1500px) {
  .ReferralCard {
    min-width: calc(50% - 55px) !important;
  }
}

@media (max-width: 700px) {
  .ReferralCard {
    min-width: calc(100% - 55px) !important;
  }
}
