@import 'src/assets/CSS/variables.scss';

.modal {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: start;
    gap: 20px;
  }

  &__footer {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__error {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
    color: $primaryRed;
  }

  &__btn {
    width: 100%;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 12px;
    margin-bottom: 20px;

    img {
      width: 80px;
      height: 80px;
    }

    h2 {
      font-size: 24px;
      font-weight: 600;
      line-height: 32.74px;
    }
  }

  &__form {
    padding-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 20px;
    overflow-y: auto;
    height: 67vh;
  }
}
