.rewards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  margin-left: 10px;
}

.rewardCard {
  max-width: 300px;
  border-radius: 8px;
  overflow: hidden;
  padding: 15px;
  background: #1b1d27;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;

  &_selected {
    color: white;
    background: #121418;
    border: 1px solid #886cff;
  }

  &__image {
    width: 100%;
    height: 60px;
    display: block;
    position: relative;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__quantity {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 16px;
    font-weight: bold;
    color: white;
  }

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px;
    color: white;
  }

  &__title {
    font-size: 16px;
    font-weight: bold;
    padding: 10px;
  }
}
