@import 'src/assets/CSS/variables.scss';

.entry {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  background: $primaryGray;
  padding: 16px;
  border-radius: 12px;

  &__info {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__row {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__name {
    font-size: 20px;
    font-weight: 600;
    line-height: 27.28px;
  }

  &__ticket {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    color: rgba(255, 255, 255, 0.5019607843);
  }

  &__text {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 4px;
  }

  &__img {
    border-radius: 50%;
    width: 60px;
    height: 60px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }
}