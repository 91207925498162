@import 'src/assets/CSS/variables.scss';

.card {
  max-width: 230px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  border-radius: 12px;
  box-sizing: border-box;
  padding: 20px 28px;
  background: $primaryGray;

  @media (max-width: 1000px) {
    max-width: none;
    width: 100%;
  }

  @media (max-width: 750px) {
    padding: 18px;
  }

  &__avatar {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid $primaryYellow;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
      object-position: center;
    }
  }

  overflow: hidden;
  
  &__name {
    width: 100%;
    font-size: 20px;
    font-weight: 600;
    line-height: 27.28px;
    text-align: center;
    
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  
  &__ticket {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.79px;
    color: rgba(255, 255, 255, 0.5019607843);
  }
  
  &__info {
    width: 100%;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 4px;
    min-height: 54px;
  }
}
