@import 'src/assets/CSS/variables.scss';


.rafflesBlock {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__cardList {
    width: 100%;
    display: grid;
    align-items: stretch;
    grid-template-columns: repeat(3, 1fr);
    gap: 24px;

    @media (max-width: 1064px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__title {
    color: white;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__notFound {
    color: white;
    font-size: 30px;
    font-weight: 800;
    line-height: 48px;
    text-align: center;
  }
}
