@import 'src/assets/CSS/variables.scss';

.card {
  display: flex;
  align-items: stretch;
  gap: 20px;
  background: $primaryGray;
  border-radius: 10px;
  padding: 24px;

  @media (max-width: 868px) {
    flex-direction: column;
  }

  &__timer {
    width: 100%;
  }

  &__divider {
    width: 1px;
    background-color: hsl(0, 0%, 100%);
    opacity: 0.2;
  }

  &__block1,
  &__block2,
  &__block3 {
    flex: 1 1 100%;
  }

  &__block1 {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 868px) {
      align-self: center;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    align-items: start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 8px;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
  }

  &__desc {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    color: #ffffff80;
    inline-size: 100%;
    max-inline-size: 350px;
    overflow-wrap: break-word;

    @media (max-width: 868px) {
      max-inline-size: 100%;
    }
  }

  &__descList {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;

    color: rgba(255, 255, 255, 0.5019607843);
    list-style: disc;
    list-style-position: inside;
  }

  &__img {
    display: flex;
    justify-content: start;
    height: 100px;
    width: 100px;

    img {
      width: 100%;
      height: auto;
    }
  }

  &__prize {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 24px;
    font-weight: 800;
    line-height: 32.74px;

    span {
      font-size: 18px;
      font-weight: 500;
      line-height: 24.55px;
      color: #ffffff80;
    }

    svg {
      color: $primaryGreen;
    }
  }

  &__block2 {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }

  &__timers {
    display: flex;
    align-items: center;
    gap: 25px;

    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
  }

  &__timer {
    display: flex;
    align-items: center;
    flex-direction: column;
    gap: 5px;

    font-size: 16px;
    font-weight: 600;
    line-height: 19.36px;
  }

  &__block3 {
    display: flex;
    flex-direction: column;
    gap: 19px;
  }

  &__requirements {
    flex: 1 1 100%;
    display: flex;
    flex-direction: column;
    gap: 19px;
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
  }

  &__desc {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: left;
    color: #ffffff50;
  }

  &__socials {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 5px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: $primaryBlack;
    border: 1px solid $primaryBlack;
    padding: 12px;

    width: 48px;
    height: 48px;
  }
}
