@import url('../../../assets/CSS/mixins.scss');

.about {
  display: flex;
  flex-direction: column;
  gap: 40px;

  &__cardList {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 24px;

    @media (max-width: 768px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__title {
    color: white;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;

    @media (max-width: 768px) {
      text-align: center;
    }
  }
}
