.root {
  &__mobile {
    display: none;
    @media (max-width: 845px) {
      display: block;
    }
  }

  &__desktop {
    display: block;
    @media (max-width: 845px) {
      display: none;
    }
  }
}

.pedestal {
  width: 100%;
  display: flex;
  justify-content: center;

  &__content {
    // height: 550px;
    // min-width: 1035px;
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  &__rankings {
    padding-top: 50px;
    display: flex;
    gap: 80px;
    width: 100%;
    position: relative;
    font-family: 'Open Sans';

    @media (max-width: 1400px) {
      gap: 20px;
    }
  }
}
