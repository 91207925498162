@import 'src/assets/CSS/variables.scss';

.block {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
  }

  &__counter {
    border: 1px solid #222833;
    background: $primaryBlack;
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    border-radius: 4px;
    padding: 10px 0px;
  }

  &__count {
    display: flex;
    flex-direction: column;
    gap: 4px;
    align-items: center;

    span:first-of-type {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.36px;
      color: #aeb6c1;
    }

    span:last-of-type {
      font-size: 14px;
      font-weight: 600;
      line-height: 16.94px;
      color: #484d58;
    }
  }

  &__endDate {
    border-radius: 4px;
    border: 1px solid #222833;
    background: $primaryBlack;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
    padding: 16px;

    font-size: 20px;
    font-weight: 600;
    line-height: 27.28px;

    span {
      color: $secondaryGray;
    }
  }
}
