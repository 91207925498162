.btn {
  font-size: 15px;
  font-weight: 600;

  &:disabled {
    background: rgb(42, 46, 56);
    border: 1px solid rgb(42, 46, 56);
    opacity: 1;
  }

  &_completed {
    background: green;
    border: 1px solid green;
  }
}

.container {
  display: flex;
  justify-content: center;
  padding: 100px 15px;
  box-sizing: border-box;

  @media (max-width: 845px) {
    padding-top: 0px;
  }
}

.list {
  width: 100%;
  max-width: 1000px;
  display: flex;
  align-items: center;
  gap: 30px;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.entry {
  box-sizing: border-box;
  display: flex;
  min-height: 100px;
  align-items: center;
  gap: 20px;
  width: 100%;
  padding: 20px;

  background: #2f333a;
  border-radius: 20px;
  box-shadow:
    0 2px 1px -1px #0003,
    0 4px 4px 0 #00000024,
    0 4px 7px 0 #0000001f;
  color: #9e9e9e;
  font-family:
    Noto Sans,
    Inter,
    Poppins,
    Montserrat,
    Helvetica,
    Arial,
    sans-serif;
  font-size: 1rem;
  font-weight: 400;
  justify-content: center;
  line-height: 1.5;
  list-style: none;

  &__icon {
    width: 50px;
    height: 50px;
    min-width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__text {
    flex: 1 1 100%;
    display: flex;
    align-items: center;
    gap: 20px;
    justify-content: space-between;

    // white-space: nowrap;
    font-size: 15px;
    font-weight: 600;

    @media (max-width: 845px) {
      flex-direction: column;
      align-items: start;
      gap: 10px;
      text-align: left;
    }
  }

  &__wager {
    color: white;
    font-weight: 600;
  }

  &__action {
    display: flex;
    justify-content: right;
    text-transform: uppercase;
  }
}
