@import 'src/assets/CSS/variables.scss';

.raffle {
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: white;
  padding: 90px 0 150px 0;

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  &__title {
    // padding-top: 40px;

    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;
  }

  &__card {
    margin-bottom: 110px;
  }
}
