.buttonGroup {
  display: flex;
  justify-content: center;
  max-width: 346px;
  margin: 0 auto;
  padding: 40px 16px;

  &__content {
    display: flex;
    border-radius: 25px;
    overflow: hidden;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    color: white;
    text-align: center;
    flex: 1;

    background: #2d2f37;

    border-radius: 0px;

    &:first-child {
      border-radius: 25px 0px 0px 25px;
    }

    &:last-child {
      border-radius: 0px 25px 25px 0px;
    }

    @media (max-width: 768px) {
      &:first-child {
        border-radius: 25px;
      }

      &:last-child {
        border-radius: 25px;
      }
    }
  }

  &__active {
    background: linear-gradient(104.01deg, #5b18a5 0%, #831ee9 100%);
  }

  // @media (max-width: 768px) {
  //   display: none;
  // }
}

.ranking {
  min-width: 370px;
  height: 515px;
  // margin: 5px 40px;
  border-radius: 10px;
  position: relative;

  &__content {
    position: absolute;
    bottom: 0;
    min-width: 370px;
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 4px 4px 0px rgba(0, 0, 0, 0.14),
      0px 4px 7px 0px rgba(0, 0, 0, 0.12);

    &:hover {
      position: absolute;
      bottom: 0;
    }
  }

  &__userIcon {
    position: absolute;
    width: 150px;
    height: 150px;
    left: 105px;
    top: -75px;
    border-radius: 50%;
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 4px 4px 0px rgba(0, 0, 0, 0.14),
      0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important;

    img {
      height: 150px;
      width: 150px;
      border-radius: 50%;
    }
  }

  &_gold {
    height: 500px;
    border-radius: 10px;
    background: #3c4155;
    background: rgb(60, 65, 85);
    background: linear-gradient(
      180deg,
      #3b4055 0%,
      rgba(91, 81, 52, 1) 20%,
      #ffce00 100%
    );
    box-shadow: rgba(255, 221, 0, 0.2) 0 0 30px !important;
    cursor: pointer;

    &:hover {
      background: linear-gradient(
        180deg,
        #3b4055 0%,
        rgb(108, 95, 60) 20%,
        #ffdd00 100%
      );
    }
  }

  &_silver {
    height: 430px;
    background: rgb(82, 82, 82);
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      rgba(60, 65, 85, 1) 0%,
      rgba(82, 82, 82, 1) 20%,
      rgba(194, 194, 194, 1) 100%
    );
    box-shadow: rgba(60, 65, 85, 0.5) 0 0 30px !important;
    cursor: pointer;

    &:hover {
      border-radius: 10px;
      background: linear-gradient(
        180deg,
        rgba(60, 65, 85, 1) 0%,
        rgb(94, 91, 91) 20%,
        rgb(210, 210, 210) 100%
      );
    }
  }

  &_bronze {
    height: 390px;
    background: rgb(64, 49, 36);
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      rgba(60, 65, 85, 1) 0%,
      rgba(168, 127, 91, 1) 20%,
      rgba(64, 49, 36, 1) 100%
    );
    box-shadow: rgba(82, 61, 44, 0.5) 0 0 30px !important;
    cursor: pointer;

    &:hover {
      border-radius: 10px;
      background: linear-gradient(
        180deg,
        rgba(60, 65, 85, 1) 0%,
        rgb(179, 126, 79) 20%,
        rgb(82, 61, 44) 100%
      );
    }
  }
}

.user {
  position: relative;
  height: 100%;

  .User-info-container-silver {
    height: 430px;
  }

  .User-info-container-gold {
    height: 100%;
    // height: 500px;
  }

  &__info {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    align-text: center;
    bottom: 0;

    > span,
    > h4 {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      width: 100%;
      text-align: center;
    }

    > h4 {
      font-size: 36px;
      font-weight: 700;
      padding: 5px 0 5px 0;
    }

    > span {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &__username {
    font-size: 50px;
    font-family: DrukTextWide;
    text-shadow: rgba(0, 0, 0, 0.45) 0px 0px 8px;
    padding: 3px 0px;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }

  &__prizes {
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.27);
    height: 40px;
    color: white;
    font-weight: 700;
    width: 100%;
    text-align: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 0;
  }

  &__title {
    text-transform: uppercase;
    font-size: 32px;
    width: 255px;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    text-align: center;
  }
}

.pedestalCard {
  &__prizes {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 43px;
      background: rgba(0, 0, 0, 0.27);
      border-radius: 0px 0px 10px 10px;
      padding-bottom: 5px;
    }
  }

  &__prize {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: white;
    position: relative;
    z-index: 1;
    padding-bottom: 5px;

    &_count {
      text-transform: uppercase;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
    }

    &_title {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    div {
      text-transform: uppercase;
      font-size: 32px;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      text-align: center;
    }

    span {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &__bottomTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 100px;

    div {
      margin: 0px 50px;
      width: 100%;
      font-weight: 600;
      font-size: 13px;
      color: white;
      text-transform: uppercase;
      // font-size: 32px;
      // text-transform: uppercase;
      // font-weight: 700;
      // text-align: center;
    }
  }
}
