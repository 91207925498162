@import '../../../assets/CSS/variables.scss';

.card {
  padding: 0px 32px 32px 32px;
  border-radius: 24px;
  position: relative;
  color: white;
  overflow: hidden;

  &__bg {
    content: '';
    position: absolute;
    top: 10%;
    left: 0;
    right: 0;
    bottom: 0;
    background: $primaryGray;
    border-radius: 24px;
  }

  &__content {
    height: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    position: relative;
    z-index: 1;
  }

  &__head {
    flex: 1 1 100%;
    height: 100%;
  }

  &__image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 106px;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__icon {
    width: 200px;
  }

  &__titleBlock {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__title {
    // flex: auto;
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;

    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__text {
    color: #ffffff80;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: left;
  }
}
