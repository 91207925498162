.block {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;

  &__logo {
    width: 80px;
    height: 80px;

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;
    margin-bottom: 12px;
  }

  &__desc {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: center;
    color: #ffffff80;
  }

  &__info {
    display: flex;
    flex-direction: column;
    // align-items: center;
    gap: 24px;
  }

  &__btnContainer {
    display: flex;
    justify-content: space-between;
    gap: 12px;

    button,
    a {
      width: 100%;
    }

    button {
      padding: 16px;
    }
  }

  &__btnContent {
    display: flex;
    align-items: center;
    gap: 5px;
  }
}
