.input {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__label {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
  }

  &__error {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    color: red;
  }

  &__field {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    color: white;
    background: #202431;
    padding: 13px 16px;
    border: none;
    border-radius: 6px;

    &:disabled {
      background: #2a2f3a;
      opacity: 0.85;
    }
  }
}
