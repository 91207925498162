@import 'src/assets/CSS/variables.scss';

.winners {
  display: flex;
  flex-direction: column;
  gap: 38px;

  &__list {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    align-items: stretch;
    gap: 12px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(4, 1fr);
    }

    @media (max-width: 750px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 600px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 460px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 40px;

    @media (max-width: 450px) {
      flex-direction: column;
      align-items: center;
      gap: 20px;
    }
  }

  &__title {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;

    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: $secondaryGray;
    }
  }
}

.modal {
  &__title {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: center;
    margin-bottom: 20px;
  }

  &__list {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    gap: 12px;
    height: 480px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 10px;
  }

  &__item {
    border: 1px solid $primaryYellow;
    border-radius: 12px;
    max-width: 230px;
    width: 100%;

    @media (max-width: 620px) {
      max-width: none;
    }
  }
}
