@import 'src/assets/CSS/variables.scss';

.card {
  display: flex;
  align-items: stretch;
  gap: 20px;
  background: $primaryGray;
  border-radius: 10px;
  padding: 24px;

  @media (max-width: 1000px) {
    flex-direction: column;
  }

  &__divider {
    width: 1px;
    background-color: hsl(0, 0%, 100%);
    opacity: 0.2;
  }

  &__block1,
  &__block2,
  &__block3 {
    flex: 1 1 100%;
  }

  &__block1 {
    display: flex;
    flex-direction: column;
    gap: 16px;

    @media (max-width: 1000px) {
      align-self: center;
      align-items: center;
    }
  }

  &__info {
    display: flex;
    align-items: start;
    flex-direction: column;
    text-align: left;
    margin-bottom: 8px;

    @media (max-width: 1000px) {
      align-self: center;
      align-items: center;
    }
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
  }

  &__desc {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    color: #ffffff80;
  }

  &__img {
    display: flex;
    justify-content: start;
    width: 90px;
    height: 90px;

    img {
      border-radius: 10px;
      object-fit: cover;
      object-position: center;
      width: 100%;
      height: 100%;
    }
  }

  &__prize {
    display: flex;
    align-items: center;
    gap: 8px;

    font-size: 24px;
    font-weight: 800;
    line-height: 32.74px;

    span {
      font-size: 18px;
      font-weight: 500;
      line-height: 24.55px;
      color: #ffffff80;
    }

    svg {
      color: $primaryGreen;
    }
  }

  &__block2 {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__block3 {
    display: flex;
    flex-direction: column;
    gap: 19px;
  }
}
