.admin {
  // position: relative;
  &__container {
    display: flex;
  }

  &__nav {
    position: sticky;
  }

  &__content {
    padding: 60px 40px 150px;
  }
}
