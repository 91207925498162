.item {
  padding: 16px 20px;
  background: linear-gradient(90deg, #333a53 0%, #333a53 58.22%);
  border-radius: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 50px;
  // overflow: hidden;

  &:hover {
    background: linear-gradient(90deg, #333a40 0%, #333a40 58.22%);
  }

  li {
    display: flex;
    justify-content: start;
    text-align: left;

    &:first-child {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
    }
  }

  &__select {
    max-width: 170px;
    width: 100%;
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 8px;

    button {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}
