.carousel-container {
  margin: 0 auto;
  //width: 80%;
  height: 500px;
  position: relative;
  perspective: 1000px;
  overflow: hidden;
}

.carousel {
  height: 100%;
  width: 100%;
  position: absolute;
  transform-style: preserve-3d;
  transition: transform 1s;
}

.carousel div {
  transform-style: preserve-3d;
}

.item-backdrop {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  //background: #000;
  padding-top: 250px;
  .Clip {
    margin-left: calc(50% - 250px);
  }
  //z-index: 100;
  display: flex;
  justify-content: center;
}

.item {
  display: block;
  position: absolute;
  width: 80%;
  left: 10%;
  line-height: 200px;
  //font-size: 5em;
  text-align: center;
  color: #fff;
  opacity: 0.95;
  border-radius: 10px;
  transition: transform 1s;
  //filter: blur(5px);
  transition: 0.3s;
}

.Viewing {
  filter: unset;
  transition: 0.3s;

  .Clip {
    //width: calc(100% - 200px);
  }
}

.a {
  transform: rotateY(0deg) translateZ(250px);
}
.a .item {
  //background: #ed1c24;
}
.b {
  transform: rotateY(60deg) translateZ(250px) rotateY(-60deg);
}
.b .item {
  //background: #0072bc;
}
.c {
  transform: rotateY(120deg) translateZ(250px) rotateY(-120deg);
}
.c .item {
  //background: #39b54a;
}
.d {
  transform: rotateY(180deg) translateZ(250px) rotateY(-180deg);
}
.d .item {
  //background: #f26522;
}
.e {
  transform: rotateY(240deg) translateZ(250px) rotateY(-240deg);
}
.e .item {
  //background: #630460;
}
.f {
  transform: rotateY(300deg) translateZ(250px) rotateY(-300deg);
}
.f .item {
  //background: #8c6239;
}

.next,
.prev {
  color: #939393;
  position: absolute;
  top: 200px;
  //padding: 1em 2em;
  cursor: pointer;
  background: transparent;
  border-radius: 5px;
  //border-top: 1px solid #FFF;
  //box-shadow: 0 5px 0 #999;
  transition:
    box-shadow 0.1s,
    top 0.1s;
}
.next:hover,
.prev:hover {
  color: #fff;
}

.next {
  right: calc(50% - 700px);
}
.prev {
  left: calc(50% - 700px);
}
