.timer {
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  &__text {
    font-size: 18px;
    font-weight: 400;
    color: #9a9a9a;

    text-transform: uppercase;
    font-weight: 600;

    span {
      color: white;
    }
  }
}
