.page {
  height: 100vh;
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  &__loading {
    font-size: 2rem;
    font-weight: bold;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 1rem;
  }
}
