@import 'src/assets/CSS/variables.scss';

.raffle {
  display: flex;
  flex-direction: column;
  gap: 40px;
  color: white;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  &__search {
    max-width: 300px;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;
  }

  &__card {
    margin-bottom: 110px;
  }

  &__actions {
    display: flex;
    justify-content: center;
    gap: 8px;

    button {
      display: flex;
      align-items: center;
      gap: 8px;
    }
  }
}

.entries {
  display: flex;
  flex-direction: column;
  gap: 38px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  &__title {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;

    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: $secondaryGray;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}
