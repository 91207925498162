.filter {
  width: 100%;
  display: flex;
  justify-content: center;

  &__item {
    background: #2d2f37;
    color: white;
    padding: 8px 25px;
    cursor: pointer;
    white-space: nowrap;
    height: 20px;
    font-weight: 700;
    min-width: 86px;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:nth-child(2) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:only-child {
      border-radius: 5px;
    }
  }

  &_active {
    background: linear-gradient(
      142deg,
      rgba(74, 37, 143, 1) 0%,
      rgba(101, 48, 194, 1) 37%,
      rgba(119, 55, 224, 1) 74%,
      rgba(119, 55, 224, 1) 100%
    );
    color: white;
  }
}
