@import 'src/assets/CSS/variables.scss';

.header {
  background-color: #202431;
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__left {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__star {
    color: #606780;
    transition: all 0.3s;
    display: flex;
    align-items: center;
    font-size: 16px;
    font-weight: 700;
    gap: 5px;

    &:hover {
      transition: all 0.3s;
      svg {
        fill: $primaryPurple;
      }
      color: $primaryPurple;
    }

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__center {
    @media (max-width: 768px) {
      display: none;
    }
  }

  &__right {
    padding-right: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 100%;

    @media (max-width: 768px) {
      display: none;
    }
  }

  &__divider {
    padding: 0px 10px;
  }

  &__menu {
    padding-right: 10px;
    display: none;

    @media (max-width: 768px) {
      display: block;
    }
  }

  &__navList {
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__link {
    color: #606780;
    transition: color 0.3s;

    &:hover {
      transition: color 0.3s;
      color: $primaryPurple;
    }
  }

  &__navItem {
    display: flex;
    align-items: center;
    gap: 7px;

    font-size: 16px;
    font-weight: 700;

    p {
      @media (max-width: 1000px) {
        display: none;
      }
    }
  }

  &__logo {
    background-color: #1b1d27;
    padding: 20px;
  }
}

.leaderboard {
  display: flex;
  align-items: center;
  gap: 10px;
  color: #606780;
  transition: color 0.3s;

  &:hover {
    transition: color 0.3s;
    color: $primaryPurple;
  }

  &__content {
    display: flex;
    flex-direction: column;

    text-align: left;
    font-size: 14px;
    font-weight: 400;
    line-height: 17.07px;

    &_bold {
      font-weight: 700;
    }
  }
}

.login {
  &__btn {
    font-size: 15.6px;
    font-weight: 700;
    line-height: 19.02px;
    text-align: center;

    border-radius: 6px;
    height: 47px;
  }
}

.profile {
  cursor: pointer;
  &__img {
    width: 35px;
    height: 35px;

    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }
  }

  &__container {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__details {
    display: flex;
    flex-direction: column;
  }

  &__username {
    font-size: 16px;
    font-weight: 600;
  }

  &__points {
    font-size: 16px;
    font-weight: 600;
    display: flex;
    align-items: center;
    gap: 5px;

    img {
      width: 20px;
      height: 20px;
    }
  }
}
