$primaryColor: #886cff;

@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat:wght@400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&display=swap');

.css-rnx94l {
  line-height: 0.95em !important;
}

* {
  padding: 0;
  margin: 0;
}

#root,
html,
body,
.App {
  // height: 100% !important;
}

body {
  background: rgb(38, 42, 56);
  //background: linear-gradient(0deg, rgba(38,42,56,1) 0%, rgba(52,56,71,1) 100%);
  // overflow-y: hidden;
}

::-webkit-scrollbar {
  background: transparent;
  width: 6px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-color: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb {
  -webkit-border-radius: 5px;
  border-radius: 5px;
  background-image: none;
  background-color: rgba(255, 255, 255, 0.1);
}

::-webkit-scrollbar-thumb:window-inactive {
  background-image: none;
  opacity: 0.5;
}

button {
  background: rgb(81, 50, 205);
  background: linear-gradient(119deg, #5b18a5 0%, #831ee9 100%);
  font-family:
    'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
    sans-serif;
  border: none;
  color: white;
  // height: 45px;
  padding: 0px 5px;
  min-width: 100px;
  border-radius: 0.375rem;
  position: relative;
  font-weight: 700;
  font-size: 0.975rem;
  user-select: none;
  text-decoration: none;
  cursor: pointer;
  appearance: none;
  box-sizing: border-box;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transform: scale(1);
  transition: transform 0.08s ease 0s;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.12),
    0px 4px 4px 0px rgba(0, 0, 0, 0.14),
    0px 4px 3px 0px rgba(0, 0, 0, 0.02);
}

.Toastify__close-button {
  min-width: auto;
}

.Balance {
  display: inline-flex;

  .Points-value {
    width: 100%;
    text-align: left;
    padding: 0;
    margin: 0;
    padding-right: 5px;
    color: white !important;
  }

  .Remaining {
    padding-left: 10px;
    padding-right: 5px;
  }

  img {
    width: 20px;
    height: 16px;
    margin-top: 2px;
    padding-left: 5px;
    padding-right: 2px;
  }
}

.BalanceNew {
  display: inline-flex;

  .Points-value {
    width: 100%;
    text-align: left;
    padding: 0;
    margin: 0;
    padding-right: 0px;
    color: white !important;
  }

  .Remaining {
    padding-left: 10px;
    padding-right: 5px;
  }

  img {
    width: 20px;
    height: 16px;
    margin-top: 3px;
    padding-left: 10px;
    padding-right: 4px;
  }
}

.App {
  text-align: center;
  // overflow: auto;
}

.App-header-container {
  position: sticky;
  top: 0;
  z-index: 1001;
}

.App-navigation {
  width: 240px;
  height: 100%;
  background: #1b1d27;
  border-right: 1px solid #2a2e38;
  margin-top: -1px;

  .App-navigation-search {
    width: 240px;
    height: 80px;
    border-top: 1px solid #2a2e38;
    border-bottom: 1px solid #2a2e38;
    .search-input {
      width: 205px;
      height: 45px;
      border: 1px solid #2a2e38;
      border-radius: 5px;
      margin: 17px 19px 19px 19px;
      background: #080808;
      display: inline-flex;

      img {
        height: 17px;
        width: 50px;
        padding: 15px 0px;
      }

      input {
        width: calc(100% - 50px);
        background: none;
        border: none;
        color: white;
      }

      input:focus {
        outline: none;
      }
    }

    .search-input:hover {
      background: #202429;
      cursor: text;
    }
  }

  .App-navigation-scrollable {
    width: 240px;
    height: calc(100% - 90px);
    overflow-x: hidden;
    overflow-y: scroll;

    .App-navigation-links {
      ul {
        li {
          cursor: pointer;
        }
      }
    }

    ul {
      color: white;
      list-style: none;
      margin: 0;
      padding: 0;

      li {
        padding: 0 25px 0 10px;
        margin: 0;
        font-size: 16px;
        white-space: nowrap;
        height: 60px;
        font-weight: 700;
        color: #d0d0d0;
        font-family: 'Montserrat', sans-serif;
        display: inline-flex;
        position: relative;
        width: 240px;

        .Navigation-icon {
          width: 30px;
          padding: 22px 0 0 0;
        }
        .Navigation-text {
          width: calc(100% - 30px);
          text-align: left;
          padding: 22px 0px;
          font-size: 14px;
          font-weight: 800;
        }

        img {
          padding: 0;
          width: 15px;
        }
        span {
          padding: 22px 50px 0 10px;
          width: 100%;
        }
      }

      li.Active {
        min-width: 100px;
        color: $primaryColor;

        svg {
          path {
            stroke: $primaryColor !important;
          }
          path.Fill {
            fill: $primaryColor !important;
          }
        }
      }

      li.Active::after {
        opacity: 1;
        position: absolute;
        content: '';
        height: 3rem;
        width: 0.25rem;
        background-color: $primaryColor;
        border-radius: 0 1.5rem 1.5rem 0;
        left: 0;
        top: 5px;
        transition: all 0.2s ease-in-out;
      }
    }
  }

  .App-navigation-coin {
    width: 240px;
    height: 80px;
    border-bottom: 1px solid #2a2e38;
    overflow: hidden;
    display: inline-flex;

    .Coin-case {
      width: 40px;
      height: 30px;
      border: 1px solid #2a2e38;
      margin: 15px 15px;
      border-radius: 5px;
      padding: 10px 10px;

      img {
        width: 28px;
        height: 28px;
        overflow: hidden;
      }
    }

    .Coin-details {
      width: 195px;
      height: 80px;
      padding-top: 25px;

      h6,
      p {
        width: 100%;
        text-align: left;
        color: #9ba5b4;
        font-size: 13px;
      }

      h6 {
        color: white;
      }
    }
  }
}

.App-header {
  //border-bottom: 1px solid #2a2e38;
  width: 100%;
  background: #202431;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

  .App-view-type {
    width: 240px;
    height: 75px;
    background: #1b1d27;
    border-right: 1px solid #2a2e38;
    display: inline-flex;

    .App-content-type {
      width: calc(100% - 65px);
      height: 75px;
      padding: 0;

      ul.Content-type-switch {
        width: calc(100% - 15px);
        height: 45px;
        margin-right: 20px;
        margin-top: 13px;
        margin-bottom: 20px;
        border: 1px solid #2a2e38;
        border-radius: 5px;

        li {
          width: 50%;
          height: 45px;
          background: #202429;
          border-top-left-radius: 5px;
          border-bottom-left-radius: 5px;
        }

        li:first-child {
          border-right: 1px solid #2a2e38;
          background: $primaryColor;
        }
      }
    }
  }

  ul {
    color: white;
    list-style: none;
    display: inline-flex;
    margin: 0;
    padding: 0;

    li {
      padding: 0 25px 0 10px;
      margin: 0;
      font-size: 16px;
      white-space: nowrap;
      height: 50px;
      font-weight: 700;
      color: #d0d0d0;
      font-family: 'Montserrat', sans-serif;
      display: inline-flex;
      img {
        padding: 0;
      }
      span {
        padding: 22px 50px 0 10px;
      }
    }

    li.Active {
      min-width: 100px;
      background-color: rgba(0, 255, 131, 0.03);
      background-image: url('https://btcs.gg/purple.png');
      background-position: right 0px bottom;
      border-left: 1px solid #2d007d;
    }
  }

  .App-header-main {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    height: 90px;
    width: 100%;

    .App-logo {
      height: 90px;
      width: 70px;
      max-width: 70px;
      min-width: 70px;
      display: flex;
      justify-content: left;
      padding-left: 20px;
      cursor: pointer;
      background: #1b1d27;

      img {
        margin-top: 20px;
      }
    }

    .title-icon-container {
      display: inline-flex;
      color: #606780;
      align-items: center;
    }

    .App-horizontal-nav {
      height: 50px;
      width: 100%;
      display: flex;
      justify-content: center;

      a {
        padding: 0;
        margin: 0;
        color: unset;
        display: inline-flex;
        text-decoration: none;
      }

      li {
        display: inline-flex;

        p {
          // padding: 15px 0;

          margin: 0;
          color: #616781;
        }
      }

      img {
        padding-right: 8px;
      }

      svg {
        padding-right: 8px;
        stroke-width: 0px;
        fill: #606780;

        path {
          stroke: #606780;
          fill: #606780;
        }
      }

      .VIP {
        .cls-1 {
          fill: none;
        }
        .cls-1,
        .cls-2 {
          stroke-width: 0px;
        }
        .cls-2 {
          fill: #606783;
        }
      }

      .Hunts {
        .cls-1,
        .cls-2,
        .cls-3 {
          fill: none;
        }

        .cls-1,
        .cls-4 {
          stroke-width: 0px;
        }

        .cls-2 {
          stroke-linecap: round;
          stroke-width: 3.479px;
        }

        .cls-2,
        .cls-3 {
          stroke: #606780;
          stroke-miterlimit: 10;
        }

        .cls-4 {
          fill: #606780;
        }

        .cls-3 {
          stroke-width: 4.638px;
        }
      }
      li:hover {
        p {
          color: #856efc;
        }

        svg {
          path {
            stroke: #856efc !important;
          }
          path.Fill {
            fill: #856efc !important;
          }

          .cls-2,
          .cls-3 {
            stroke: #856efc;
            stroke-miterlimit: 10;
          }

          .cls-4 {
            fill: #856efc;
          }
        }

        .VIP {
          .cls-1 {
            fill: none;
          }
          .cls-1,
          .cls-2 {
            stroke-width: 0px;
          }
          .cls-2 {
            fill: #856efc;
          }
        }
      }
    }

    .App-horizontal-nav ul li:hover {
      svg {
        fill: #856efc;

        path {
          stroke: #856efc;
          fill: #856efc;
        }
      }
    }

    .App-bar-right {
      height: 55px;
      width: 370px;
      display: inline-flex;

      .Leaderboard-header-button {
        height: 45px;
        width: 150px;
        margin-top: 5px;
        display: inline-flex;
        border-right: 2px solid #606781;
        cursor: pointer;

        .Leaderboard-icon {
          width: 50px;
          padding: 5px 0 0 10px;
          //background: cyan;
          img {
            width: 30px;
          }

          .cls-2 {
            fill: none;
          }

          svg {
            stroke-width: 0px;
            fill: #606780;

            path {
              stroke: #606780;
            }
            path {
              fill: #606780;
            }
          }
        }

        .Leaderboard-content {
          width: 150px;

          ul {
            display: flex;
            flex-direction: column;
            //background: yellow;
            overflow: hidden;
            padding-top: 2px;

            li:first-child {
              font-weight: 400;
              color: #777f9c !important;
            }
            li {
              width: 100%;
              height: 20px;
              font-size: 14px !important;
              color: #777f9c !important;
            }
          }
        }
      }
      .Leaderboard-header-button:hover {
        svg {
          fill: #856efc !important;
          path {
            stroke: #856efc !important;
          }
          path {
            fill: #856efc !important;
          }
        }
        .Leaderboard-content {
          ul {
            li:first-child {
              color: #856efc !important;
            }
            li {
              color: #856efc !important;
            }
          }
        }
      }

      .App-userbar {
        height: 55px;
        min-width: 170px;
        display: inline-flex;
        justify-content: right;

        li {
          padding: 0;
          margin: 0;
          margin-right: 10px;
          text-align: left;

          img {
            border-radius: 50%;
          }
        }

        p {
          color: white;
          font-weight: 500;
        }

        li.Profile-image {
          padding-top: 10px;
          cursor: pointer;

          img {
            width: 45px;
            height: 45px;
          }
        }

        .User-details {
          display: flex;
          flex-direction: column;
          justify-content: right;
          cursor: pointer;
          padding-top: 8px;
          font-weight: 600 !important;

          a {
            width: 100%;
            text-align: right;
          }

          .Balance {
            display: inline-flex;
            justify-content: right;
            font-size: 13px;
            color: white;

            span {
              width: 100%;
              text-align: left;
              padding: 1px 0;
              margin: 0;
            }

            img {
              width: 15px;
              padding-right: 5px;
            }
          }
        }

        img.ProfileImage {
          width: 35px !important;
          height: 35px !important;
        }

        a {
          color: white !important;
          text-decoration: none !important;
        }
      }
    }

    .App-userbar-mobile {
      height: 0;
      width: 0;
      overflow: hidden;
      visibility: hidden;
    }
  }
}

.Infinite-horizontal {
  width: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
  display: inline-flex;
  justify-content: left;
  display: inline-flex;
  overflow-x: scroll;
  overflow-y: hidden;
  box-sizing: content-box;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  .Bet-size-filter {
    display: flex;
    justify-content: right;
  }
}
.Infinite-horizontal::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.StreamerName {
  width: calc(100% - 50px);
  color: white;
  text-transform: uppercase;
  font-family: DrukTextWide;
  font-size: 16px;
  text-align: left;
  padding: 50px 0 0 25px;
}

.Streamer-videos {
  width: calc(100% - 50px);
  border-bottom: 1px solid #707070;

  &:last-child {
    border-bottom: none;
  }
}

.App-footer {
  //border-top: 1px solid $primaryColor;
  min-height: 260px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);

  .Footer-Main {
    height: 230px;

    .App-logo {
      width: 200px;
      padding: 25px;
    }

    .Footer-socials {
      width: 100%;
      display: inline-flex;
      justify-content: center;

      ul {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        list-style: none;

        li {
          padding: 15px 25px;
        }
      }
    }

    .Footer-navigation {
      width: 100%;
      display: inline-flex;
      justify-content: center;

      ul {
        width: 100%;
        display: inline-flex;
        justify-content: center;
        list-style: none;

        span {
          font-size: 12px;
          color: #9a9a9a;
        }

        li {
          padding: 0 15px;
        }
      }
    }
  }

  .Footer-Signature {
    width: 100%;
    color: white;
    font-size: 11px;
  }
}

.Landing-content {
  background: linear-gradient(0deg, #252939 0%, #11131a 100%);
  overflow-x: hidden;
}

.Landing-content-leaderboard {
  background: linear-gradient(0deg, #252939 0%, #11131a 100%);
  overflow: hidden;
  padding-top: 50px;
}

@media (max-width: 500px) {
  .Landing-content-leaderboard {
    padding-top: 0px;
  }
}

.Landing-Coins {
  position: relative;
  width: 100%;
  .Gold {
    img {
      left: 0px;
      top: 150px;
      width: 12% !important;
      filter: blur(10px) !important;
    }
  }
  .Silver {
    img {
      right: 0;
      top: 400px;
      width: 8%;
    }
  }

  img {
    width: 40%;
    filter: blur(5px);
    position: absolute;
  }
}

.App-contents-container {
  // overflow: auto;
  width: 100%;
  height: calc(100% - 70px);
  display: inline-flex;
  justify-content: center;
  padding: 0 !important;
  margin: 0 !important;
}

.Section-heading {
  width: calc(100% - 40px);
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
  color: white;
  padding: 10px;
  font-weight: 900;
  font-size: 42px;
  text-transform: uppercase;
  font-family: DrukTextWide;

  h4 {
    padding-top: 40px;
    width: 100%;
    text-align: center;
  }

  svg {
    color: black !important;
    path,
    stroke {
      fill: black !important;
      color: black !important;
    }
  }
}

.Leaderboard-Pedestal-container {
  width: 100%;
  display: flex;
  justify-content: center;

  .Leaderboard-Pedestal {
    height: 550px;
    min-width: 1035px;
    display: flex;
    flex-direction: column;

    .Rankings {
      padding-top: 40px;
      display: flex;
      gap: 80px;
      width: 100%;
      position: relative;
      padding-bottom: 10px;
      font-family: 'Open Sans';

      @media (max-width: 1400px) {
        gap: 20px;
      }

      .Ranking {
        min-width: 370px;
        height: 515px;
        // margin: 5px 40px;
        border-radius: 10px;
        position: relative;

        .Content {
          position: absolute;
          bottom: 0;
          min-width: 370px;
          box-shadow:
            0px 2px 1px -1px rgba(0, 0, 0, 0.2),
            0px 4px 4px 0px rgba(0, 0, 0, 0.14),
            0px 4px 7px 0px rgba(0, 0, 0, 0.12);

          .User-icon {
            position: absolute;
            width: 150px;
            height: 150px;
            left: 105px;
            top: -75px;
            border-radius: 50%;
            box-shadow:
              0px 2px 1px -1px rgba(0, 0, 0, 0.2),
              0px 4px 4px 0px rgba(0, 0, 0, 0.14),
              0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important;

            img {
              height: 150px;
              width: 150px;
              border-radius: 50%;
            }
          }

          .User-info-container {
            position: relative;
            height: 390px;
          }

          .User-info-container-silver {
            height: 430px;
          }

          .User-info-container-gold {
            height: 100%;
            // height: 500px;
          }
          .User-info {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-end;
            align-text: center;
            bottom: 0;

            span,
            h4 {
              text-transform: uppercase;
              color: white;
              font-weight: 700;
              width: 100%;
              text-align: center;
            }

            h4 {
              font-size: 36px;
              font-weight: 700;
              padding: 5px 0 5px 0;
            }

            .Druk {
              font-size: 50px;
              font-family: DrukTextWide;
              text-shadow: rgba(0, 0, 0, 0.45) 0px 0px 8px;
              padding-top: 3px;
            }

            span {
              font-weight: 600;
              font-size: 13px !important;
            }

            .leaderboard-username {
              text-transform: uppercase;
              font-size: 18px;
              margin-top: 10px;
              background: rgba(0, 0, 0, 0.27);
              height: 40px;
              color: white;
              font-weight: 700;
              width: 100%;
              text-align: center;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
              padding: 5px 0;
            }

            .Username {
              text-transform: uppercase;
              font-size: 32px !important;
              width: 255px;
              text-transform: uppercase;
              color: white;
              font-weight: 700;
              text-align: center;
            }
          }
        }

        .Content:hover {
          position: absolute;
          bottom: 0;
          //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.4), 0px 14px 14px 0px rgba(0, 0, 0, 0.24), 0px 4px 17px 0px rgba(0, 0, 0, 0.22) !important;
        }
      }
      .Gold {
        height: 500px;
        border-radius: 10px;
        background: #3c4155;
        background: rgb(60, 65, 85);
        background: linear-gradient(
          180deg,
          #3b4055 0%,
          rgba(91, 81, 52, 1) 20%,
          #ffce00 100%
        );
        box-shadow: rgba(255, 221, 0, 0.2) 0 0 30px !important;
        cursor: pointer;
      }
      .Gold:hover {
        background: linear-gradient(
          180deg,
          #3b4055 0%,
          rgb(108, 95, 60) 20%,
          #ffdd00 100%
        );
      }
      .Silver {
        height: 430px;
        background: rgb(82, 82, 82);
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(60, 65, 85, 1) 0%,
          rgba(82, 82, 82, 1) 20%,
          rgba(194, 194, 194, 1) 100%
        );
        box-shadow: rgba(60, 65, 85, 0.5) 0 0 30px !important;
        cursor: pointer;
      }
      .Silver:hover {
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(60, 65, 85, 1) 0%,
          rgb(94, 91, 91) 20%,
          rgb(210, 210, 210) 100%
        );
      }
      .Bronze {
        height: 390px;
        background: rgb(64, 49, 36);
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(60, 65, 85, 1) 0%,
          rgba(168, 127, 91, 1) 20%,
          rgba(64, 49, 36, 1) 100%
        );
        box-shadow: rgba(82, 61, 44, 0.5) 0 0 30px !important;
        cursor: pointer;
      }
      .Bronze:hover {
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(60, 65, 85, 1) 0%,
          rgb(179, 126, 79) 20%,
          rgb(82, 61, 44) 100%
        );
      }
    }
  }
}

.Leaderboard-btn {
  display: flex;
  height: 50px;
  width: 100%;
  justify-content: center;
  margin: 10px 0;

  a {
    width: 40%;
  }
  button {
    width: 100%;
  }

  img {
    padding-right: 10px;
  }
}

.Main-heading {
  font-family: DrukTextWide;
  text-transform: uppercase;
  font-size: 3.8vw;
  text-shadow: rgba(255, 255, 255, 0.45) 0px 0px 8px;
}

.Main-heading-larger {
  font-size: 5.8vw;
  font-family: DrukTextWide;
  text-transform: uppercase;
  text-shadow: rgba(255, 255, 255, 0.45) 0px 0px 8px;
}

.Main-heading-largest {
  font-size: 5.8vw;
  font-family: DrukTextWide;
  text-transform: uppercase;
  text-shadow: rgba(255, 255, 255, 0.45) 0px 0px 8px;
}

.Heading-padded {
  padding-top: 50px;
}

.Heading-divider {
  width: 50%;
  margin: 25px 25%;
  height: 2px;
  background: rgba(255, 255, 255, 0.6);
}

.Main-subheading {
  font-size: 18px;
  font-weight: 400;
  color: #9a9a9a;
  padding: 10px 0;
}

.Landing-heading {
  width: calc(100% - 20px);
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 10px;
  font-weight: 900;
  font-size: 32px;

  h3 {
    width: 100%;
    color: #fff;
  }

  h4 {
    width: 100%;
    text-shadow: #b67fff 0px 0px 4px;
    color: #b67fff;
    text-transform: uppercase;
  }

  h2 {
    width: 100%;
    color: #fff;
  }

  span {
    width: 100%;
    color: #b67fff;
  }
}
.Landing-heading-left {
  width: calc(100% - 15px);
  text-align: left;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  h4 {
    width: 200px;
    font-size: 21px !important;
    padding: 10px;
  }
}

.Margined-Heading-1 {
  width: calc(100% - 40px) !important;
}

.App-contents {
  width: calc(100% - 0px);
  overflow-y: scroll;
  -ms-overflow-style: none; /* Hide the scrollbar for MS Edge */
  scrollbar-width: none; /* Hide the scrollbar for Mozilla Firefox */
  height: 100%;

  .Content-container {
    padding: 50px;

    p,
    span {
      color: white;
      font-weight: 600;
    }

    h2 {
      font-family: 'Montserrat', sans-serif;
      font-family: 'Caveat', cursive;
      font-size: 4vw;
      font-weight: 900;
    }

    span,
    h2 {
      text-transform: uppercase;
      font-optical-sizing: auto;
      -webkit-text-fill-color: transparent;
      background: -webkit-linear-gradient(#6c19ff, #6f20ffff);
      background-clip: border-box;
      -webkit-background-clip: text;
      text-align: center;
      text-shadow: #5b00ff 0px 0px 16px;
    }
  }

  .Headline {
    padding: 10% 0 0 10%;

    h3 {
      color: #fff;
      font-family: DrukTextWide;
      font-size: 90px;
      font-weight: 800;
      height: 80px;
      padding: 0 0 0 25px;
      text-align: left;
      text-shadow: #00000073 0 47px 46px;
      text-transform: uppercase;
    }
    h1 {
      color: #fff;
      font-family: DrukTextWide;
      font-size: 68px;
      font-weight: 800;
      margin: 0 !important;
      padding: 20px;
      text-align: left;
      text-transform: uppercase;
    }

    p {
      color: white;
      text-align: left;
      font-size: 21px;
      padding: 25px 0 65px 25px;
      font-style: italic;
      width: calc(100% - 25px) !important;
      text-shadow: rgba(0, 0, 0, 0.45) 0px 0px 8px;
      font-weight: 300;
    }
  }

  .hero {
    font-optical-sizing: auto;
    display: flex;
    justify-content: center;
    min-height: calc(100% - 20px);
    height: 100vh;
    width: 100%;
    background: rgb(113, 100, 216);
    background: linear-gradient(
      118deg,
      rgba(113, 100, 216, 1) 0%,
      rgba(42, 38, 98, 1) 100%
    );

    background: rgb(13, 11, 22);
    background: linear-gradient(
      141deg,
      rgba(13, 11, 22, 1) 0%,
      rgba(33, 28, 69, 1) 20%,
      rgba(68, 63, 154, 1) 50%,
      rgba(98, 87, 195, 1) 100%
    );

    background: rgb(131, 61, 245);
    background: linear-gradient(
      142deg,
      #9021ff 5%,
      rgba(118, 55, 224, 1) 23%,
      rgba(70, 33, 149, 1) 44%,
      rgba(41, 20, 104, 1) 69%,
      rgba(25, 16, 49, 1) 100%
    );

    position: relative;

    .Hero-Content {
      width: 100%;
      height: 100%;
      max-height: 100%;
      z-index: 100;
    }

    .Headline {
      height: 50%;
      max-height: 50%;
      overflow: hidden;
    }

    .Streamer-List-Container {
      width: 100%;
      height: 50%;
      max-height: 50%;
      position: relative;
    }

    .Streamer-List {
      width: 100%;
      left: 0;

      justify-content: center;
      overflow-x: scroll;
      overflow-y: hidden;
      overflow-x: scroll;
      overflow-y: hidden;
      scrollbar-width: none;
      display: inline-flex;
      flex-wrap: wrap;
      font-family:
        'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
        sans-serif !important;
      position: absolute;

      @media (max-width: 845px) {
        top: 100px;
      }
    }

    .Background-img {
      //width: 1140px;
      height: 600px;
      position: absolute;
      top: 100px;
      right: 0px;
    }

    .Casino-coin1 {
      height: 500px;
      position: absolute;
      top: 200px;
      left: 0px;
    }

    .Casino-coin2 {
      height: 200px;
      position: absolute;
      top: 400px;
      right: 200px;
    }
  }

  .follow-button {
    width: 190px;
    background-color: $primaryColor !important;
    border-radius: 5px !important;
    margin-top: 10px;
    color: white;
    font-weight: 600;

    svg {
      color: white;
    }
    span {
      padding-left: 10px;
    }
  }

  .StatusSymbol {
    color: #7ff998;
    margin-right: 7px;
    font-weight: 800;
    text-transform: uppercase;
  }

  .online {
    color: #7ff998 !important;
  }

  .offline {
    color: #cecece !important;
  }
}

.App-livefeed {
  width: 250px;
  height: 100%;
  background: #1a1d23;
  border-left: 1px solid #2a2e38;
  overflow: hidden;
  color: white;

  .Crates-heading {
    text-transform: uppercase;
    padding: 15px;
    font-size: 12px;
    color: #ffdd00;
    border-bottom: 1px solid #2a2e38;
    background: linear-gradient(180deg, #202429, #1b1d27);
  }

  .Crates-list {
    ul {
      list-style: none;
    }

    .Crate-container {
      width: 100%;
      height: 150px;
      justify-content: center;
      border-bottom: 1px solid #2a2e38;
      background-image: url('https://btcs.gg/purple.png');
      background-size: cover;
      background-color: #1b1d27;

      .Crate-date {
        padding-top: 5px;
        width: calc(100% - 10px);
        text-align: right;
        text-transform: uppercase;
        font-size: 12px;
        font-weight: 800;
        color: #9e9e9e;
      }

      .Crate-Content {
        .Crate-Player {
          width: 100%;
          margin-top: -10px;
          padding-left: 10px;
          text-align: left;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 900;
          color: #c4c4c4;
        }

        .Crate-Value {
          width: 100%;
          padding-left: 10px;
          text-align: left;
          text-transform: uppercase;
          font-size: 12px;
          font-weight: 800;
          display: flex;
          justify-content: left;

          img {
            padding-top: 5px;
          }

          span {
            padding-left: 5px;
            margin-top: 5px;
          }
        }
        .Crate-image {
          width: 100px;
          height: 100px;
          margin-left: 70px;
          background-image: url('https://btcs.gg/nolimit.png');
          background-position: 0% 50%;
          background-size: cover;
        }
      }
    }
  }
}

.iconBtn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.Btcs-table {
  display: flex;
  justify-content: center;
  flex-direction: column;
  color: white;

  ul {
    list-style: none;
    display: inline-flex;
    li {
      width: 50%;
    }
  }

  .Header {
    background: #1b1d27;
    padding: 20px;
    font-weight: 800;
  }

  .Entry {
    padding: 10px;
    margin-top: 5px;
    color: #9e9e9e;
    background: #2a2e38;
    font-weight: 800;
    font-size: 18px;

    li.Actions {
      display: flex;
      justify-content: center;
      button {
        margin-right: 5px;
      }
    }
  }

  .SelectedEntry {
    background: #414961;
  }
}

.Btcs-form {
  width: 100%;
  padding-bottom: 15px;

  .Form-submission {
    width: 100%;
    padding: 15px;
    display: flex;
    justify-content: left;

    button {
      margin-right: 20px;
    }
  }
  .Form-submission-right {
    justify-content: right;
  }

  .Form-input-field {
    width: 100%;

    h6 {
      width: 100%;
      text-align: left;
      color: #c4c4c4;
      padding: 15px 0 5px 15px;
    }

    .Form-input {
      width: calc(100% - 30px);
      display: inline-flex;
      background-color: #2b2f3d;
      border-radius: 5px;

      img {
        width: 25px;
        padding: 15px;
      }

      input {
        width: calc(100% - 35px);
        border: none;
        background: none;
        color: white;
        padding: 10px;
      }

      input:focus {
        outline: none;
      }

      h5 {
        padding: 20px 10px;
        color: #c4c4c4;
      }
    }

    textarea {
      width: 100%;
      height: 300px;
      color: #acacac;
      background: #1b1d27;
      border: 1px solid #2a2e38;
    }
    textarea:focus {
      outline: none;
    }
  }
}

/**
Tabs
 */

.locker-tabs {
  width: 100%;
}
.tab-list-mobile {
  float: right;
  margin: 10px;
  background: none;
  border: none;
  visibility: hidden;
  width: 0;
  height: 0;
}
.locker-tab-list {
  margin: 0 10px 10px 10px;
  width: calc(100% - 20px);
  background-color: transparent;
  border-bottom: solid 1px rgba(51, 51, 69, 0.3) !important;
  z-index: 10;
  overflow-x: auto;
  overflow-y: hidden;
}

.locker-tab-list-item img {
  margin-top: 5px;
  margin-left: 10px;
}

.locker-tab-list-item {
  display: inline-flex;
  white-space: nowrap;
  margin-right: 20px;
  list-style: none;
  float: left;
  //margin-top: -15px;
  margin-left: 5px;
  height: 44px;
  width: 100px;
  padding: 0 !important;
  font-weight: 600;
  font-size: 14px !important;
  touch-action: manipulation;
  position: relative;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  transition:
    background 300ms ease 0s,
    opacity 300ms ease 0s,
    transform 100ms ease 0s;
  color: rgb(68, 68, 68);
  cursor: pointer;
  user-select: none;
}

.locker-tab-list-item:hover {
  color: $primaryColor;
}

.locker-tab-list-active {
  border-width: 1px 1px 0 1px;
  border-bottom: solid 2px $primaryColor !important;
  color: $primaryColor;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
}

.locker-tab-content {
}

.ModalHeading {
  padding-bottom: 15px;
}

.ModalSelect {
  margin-top: 15px;
}

.GiveawayList {
  padding: 15px 0px;
  width: 100%;
  font-family: 'Open Sans';

  .Giveaway-grid {
    display: inline-flex;
    justify-content: space-between;
  }

  .GiveawayHeading {
    font-weight: 800;
    font-size: 28px;
    text-align: center;
    font-style: italic;
    color: white;
    width: 100%;
  }

  .GiveawayContent {
    position: relative;
    width: 100%;
    text-shadow: rgba(0, 0, 0, 0.245) 0px 0px 8px;

    .Links {
      width: 100%;
      display: inline-flex;
      justify-content: center;
      padding: 20px 0;
      color: white;

      .StreamerLi {
        display: inline-flex;
        justify-content: left;
        height: 40px;
        width: 50%;
        white-space: nowrap;
        padding: 0;
        margin: 0;

        img {
          padding: 0;
          margin: 0;
          width: 30px;
        }

        p {
          padding: 12px 5px;
        }
      }
      .StreamerLi:first-child {
        width: 100px;
      }
      .StreamerLi:last-child {
        width: 100px;
        padding-left: 15px;
      }
    }

    .GiveawayAction {
      font-size: 16px;
      font-weight: 600;
      padding: 10px 4px;
      text-align: center;
      width: calc(100% - 10px);
      display: inline-flex;
      justify-content: center;
      //border: 2px solid red;
      color: $primaryColor;
      background: linear-gradient(119deg, #5b18a5 0%, #831ee9 100%);
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 4px 4px 0px rgba(0, 0, 0, 0.14),
        0px 4px 7px 0px rgba(0, 0, 0, 0.12);
      color: white;
      border-radius: 5px;
      position: absolute;
      bottom: -75px;
      cursor: pointer;
      min-height: 25px !important;
      max-height: 25px !important;
    }
    .GiveawayAction:hover {
      background: linear-gradient(119deg, #5b18a5 20%, #831ee9 100%);
    }

    ul:nth-child(3) {
      li:first-child {
        border-right: 1px solid rgba(255, 255, 255, 0.42) !important;
      }
      li {
        margin-top: 10px;
      }
    }

    .Watch-req-Timers {
      width: 100%;
      display: inline-flex;
      justify-content: center;

      .InlineUl {
        display: inline-flex;
        width: 200px;
        text-transform: uppercase;

        li {
          width: 100px;
          padding: 10px 20px !important;
          margin: 0 !important;

          img {
            padding: 0 5px;
          }
        }

        div {
          margin-top: 20px;
          height: 50px;
          width: 1px;
          border-right: 1px solid rgba(255, 255, 255, 0.5);
        }
      }

      .Watch-req {
        font-weight: 600;
        width: 50px;
        font-size: 14px;
        color: white;
        padding-top: 10px;
      }
    }
    p {
      font-weight: 600;
      width: 100%;
      text-align: center;
    }
    ul {
      width: 100%;
      list-style: none;
    }

    ol:last-child {
      li:first-child {
        border-right: none;
      }
    }
    ul:last-child {
      border-right: none !important;
    }
  }
}

.Timer {
  display: inline-flex;
  width: 100%;
  padding-top: 0px;
  justify-content: center;

  div {
    margin-top: 18px;
    height: 18px;
    width: 1px;
    border-right: 3px solid rgba(255, 255, 255, 1);
  }

  ol {
    display: flex;
    flex-direction: column;
    list-style: none;
    color: white;
    width: 80px;
    margin: 0;
    padding: 0;

    li {
      width: 80px;
      overflow: hidden;

      p {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    li:first-child {
      font-size: 32px;
      font-weight: 800;
      margin-top: 4px;
    }
    .Bordered {
      border-right: 1px solid white !important;
    }
  }
}

.Challenge-Timer {
  div {
    margin-top: 13px;
    height: 18px;
    width: 1px;
    border-right: 3px solid rgba(255, 255, 255, 1);
  }

  ol {
    display: flex;
    flex-direction: column;
    list-style: none;
    color: white;
    width: 60px;
    margin: 0;
    padding: 0;

    li {
      width: 60px;
      overflow: hidden;
      font-size: 9px;

      p {
        width: 100%;
        text-align: center;
        text-transform: uppercase;
        font-weight: 500;
      }
    }

    li:first-child {
      font-size: 28px;
      font-weight: 800;
      margin-top: 4px;
    }
    .Bordered {
      border-right: 1px solid white !important;
    }
  }
}

.RaffleList {
  padding: 15px 10px;
  width: 100%;

  .RaffleHeading {
    font-weight: 800;
    text-align: center;
  }

  .RaffleContent {
    position: relative;
    height: 570px;

    .RaffleIcon {
      width: 100%;
      display: flex;
      justify-content: center;

      img {
        width: 100px;
        height: 100px;
        border-radius: 50%;
      }
    }

    .Entries-list {
      width: 100%;
      display: inline-flex;
      justify-content: space-evenly;

      .Streamer {
        width: calc(100% - 50px);
        border: 1px solid #2a2e38;
        display: flex;
        flex-direction: column;
        -moz-box-align: start;
        align-items: start;
        gap: 0.25rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background: #1b1d27;
        color: #9e9e9e;
      }
      .Entries {
        width: calc(50% - 50px);
        border: 1px solid #2a2e38;
        display: flex;
        flex-direction: column;
        -moz-box-align: start;
        align-items: start;
        gap: 0.25rem;
        padding: 1rem;
        border-radius: 0.5rem;
        background: #1b1d27;
        color: #9e9e9e;
      }

      ul {
        list-style: none;

        li:first-child {
          font-weight: 800;
          font-size: 12px;
          margin-bottom: 5px;
        }

        li:nth-child(2) {
          color: white;
          font-weight: 800;
          font-size: 18px;
        }
      }
    }

    .RaffleAction {
      font-size: 18px;
      font-weight: 600;
      padding: 15px 4px;
      text-align: center;
      width: 100px;
      display: inline-flex;
      justify-content: center;
      //border: 2px solid red;
      background-color: #886cff !important;
      border: 2px solid #886cff;
      border-radius: 5px !important;
      color: #001440;
      border-radius: 5px;
      bottom: 0;
      cursor: pointer;
    }
  }
}

.Active-filter {
  width: 100%;
  display: inline-flex;

  div {
    background: #2d2f37;
    color: white;
    padding: 8px 25px;
    cursor: pointer;
    white-space: nowrap;
    height: 20px;
    font-weight: 700;
    min-width: 86px;
  }

  div:first-child {
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  div:nth-child(2) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
  }

  div:only-child {
    border-radius: 5px;
  }

  div.Active {
    background: rgb(74, 37, 143);
    background: linear-gradient(
      142deg,
      rgba(74, 37, 143, 1) 0%,
      rgba(101, 48, 194, 1) 37%,
      rgba(119, 55, 224, 1) 74%,
      rgba(119, 55, 224, 1) 100%
    );
    color: white;
  }
}

.CenteredFilter {
  justify-content: center;
  font-family: RuneScape;
  font-size: 23px;

  div {
    max-height: 50px;
    min-width: 120px;
    max-width: 120px;
    padding: 10px 10px 13px 10px;
  }

  img {
    height: 17px;
    padding: 0px 5px;
  }
}

.Custom-Paginated {
  width: 100%;
  display: flex;
  justify-content: right;

  .Pagination-container {
    justify-content: right;
    padding: 10px;
    display: inline-flex;
    width: 600px;
    color: #acacac;

    div:first-child {
      padding: 15px;
    }

    span {
      font-weight: 800;
      color: white;
    }
  }
}

.Custom-button {
  display: inline-flex;
  -moz-box-align: center;
  align-items: center;
  -moz-box-pack: center;
  justify-content: center;
  box-sizing: border-box;
  outline: 0px;
  margin: 0px;
  cursor: pointer;
  user-select: none;
  vertical-align: middle;
  appearance: none;
  text-decoration: none;
  font-family:
    'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
    sans-serif;
  font-weight: 500;
  font-size: 0.875rem;
  line-height: 1.25;
  text-transform: uppercase;
  max-width: 360px;
  min-width: 90px;
  position: relative;
  min-height: 48px;
  flex-shrink: 0;
  padding: 12px 16px;
  overflow: hidden;
  white-space: normal;
  text-align: center;
  flex-direction: column;
  border-radius: 0.5rem;
  background-color: #1b1d27;
  border: 1px solid #2a2e38;
  transition: background-color 0.2s ease-in-out;
  margin-left: 15px;
}

.Video-Details {
  width: 100%;
  display: inline-flex;

  .Video-Uploader {
    width: 65px;
    display: flex;
    justify-content: left;
    padding: 10px 0 0 0;
    //background: yellow;

    img {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 4px 4px 0px rgba(0, 0, 0, 0.14),
        0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important;
    }
  }

  .Video-Title {
    width: calc(100% - 65px);
    text-overflow: ellipsis;
    overflow: hidden;
    padding-top: 15px;

    span,
    p {
      color: #9e9e9e;
      font-size: 15px;
      font-family:
        'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
        sans-serif;
    }
    span {
      color: white;
      font-weight: 800;
      white-space: nowrap;
      width: 100px !important;
    }
    p {
      font-weight: 600;
    }
  }
}

@font-face {
  font-family: DrukTextWide;
  src: url('../../../public/DrukTextWide-Super.ttf') format('opentype');
}

@font-face {
  font-family: RuneScape;
  src: url('../../../public/runescape.ttf') format('truetype');
}

.Video-case {
  padding: 15px 0px;
  margin: 0px 25px 25px 0;
}

.CardHover {
  width: 335px;
  transition: 0.3s;

  .MuiTypography-h6 {
    font-size: 18px;
    padding-top: 10px;
    font-weight: 800 !important;
    font-family: DrukTextWide;
  }
}

.CardCardHover {
  margin: 5px !important;
  width: 335px;
  height: 125px;
  transition: 0.3s;

  img {
    transition: 0.3s;
  }
  .MuiTypography-h6,
  p {
    transition: 0.3s;
  }
}

.CardCardHover:hover {
  width: 341px;
  margin: 2px 2px !important;
  height: 126px;
  transition: 0.3s;

  img {
    width: 83px !important;
    height: 83px !important;
    transition: 0.3s;
  }

  .MuiTypography-h6 {
    padding-top: 12px;
  }
  .MuiTypography-h6,
  p {
    padding-left: 5px !important;
    transition: 0.3s;
  }
}

.CardHover:hover {
  width: 340px;
  margin-left: -2px;
  transition: 0.2s;
}

.CardHover-video {
  width: 445px;
  min-width: 445px;
  max-width: 445px;
  transition: 0.6s !important;
}

.CardHover-video:hover {
  width: 500px !important;
  min-width: 500px !important;
  max-width: 500px !important;
  transition: 0.6s;

  .Card {
    width: 500px !important;
    min-width: 500px !important;
    max-width: 500px !important;
    transition: 0.6s;
  }
}

.App-menu-button {
  margin: 12px 21px;
  width: 22px;
  height: 19px;
  background-image: url('https://btcs.gg/navigation/menu.svg');
  background-size: cover;
  cursor: pointer;
}

.App-menu-button:hover {
  background-image: url('https://btcs.gg/navigation/menu_.svg');
}

.Mobile-navigation {
  width: 100%;
  height: 100%;
  position: fixed;
  left: 0;
  top: 0;
  background: rgb(48, 50, 58);
  background: linear-gradient(
    0deg,
    rgba(48, 50, 58, 1) 0%,
    rgba(33, 36, 48, 1) 100%
  );
  z-index: 1000;

  .Mobile-Close {
    width: calc(100% - 20px);
    padding: 25px 0 0 20px;
    display: flex;
    justify-content: left;

    svg {
      color: #44495b;
    }

    p {
      padding: 2px 20px;
      font-weight: 800;
      color: white;
    }
  }

  .Mobile-Balance {
    width: calc(100% - 20px);
    padding: 0 20px 0 0;
    display: flex;
    justify-content: right;

    .Balance {
      background-color: #44495b;
      border-radius: 10px;
      padding: 2px 5px;
      color: white;
      font-weight: 600;

      img {
        padding-right: 10px;
      }
    }
  }

  .Mobile-Profile {
    .Profile-image {
      width: 100%;
      display: inline-flex;
      justify-content: center;

      img {
        width: 150px;
        border-radius: 50%;
        margin: 25px 0;
      }
    }
    .Profile-name {
      width: 100%;
      display: inline-flex;
      justify-content: center;

      span {
        color: white;
        font-size: 32px;
        font-weight: 800;
      }
    }

    .Profile-rank {
      width: 100%;
      display: inline-flex;
      justify-content: center;

      span {
        color: white;
        font-size: 18px;
      }
    }
  }

  .Mobile-Menu {
    ul {
      width: 100%;
      list-style: none;
      padding: 20px 40px;
      max-height: 270px;
      overflow-y: scroll;

      li {
        a {
          width: calc(100% - 80px);
          border-bottom: 1px solid rgba(255, 255, 255, 0.15);
          display: flex;
          flex-direction: row;
          color: #fff;

          .Menu-icon {
            width: 50px;
            padding: 20px 0 20px 10px;
            color: #ffffff;
          }

          .Menu-option {
            padding: 23px 10px;
            width: calc(100% - 50px);
            display: flex;
            justify-content: left;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }
  }

  .Mobile-Tos {
    width: 100%;
    height: calc(100% - 710px);
    position: relative;

    .Tos {
      position: fixed;
      width: 100%;
      bottom: 0;
      display: flex;
      justify-content: left;
      flex-direction: column;
      padding: 10px 40px;
      &__link {
        width: 100%;
        text-align: left;
        padding-bottom: 10px;
        font-size: 13px;
        color: #6a728d !important;
      }
    }
  }
}

.Desktop-nav {
  .MuiPaper-rounded {
    color: #d0d0d0;
    background: #2a2e38 !important;
  }
}

.Heading-Landing {
  margin-top: 150px;
}

.Heading-Landing-2 {
  margin-top: 100px;
}

.Small-Large-btn {
  height: 30px;
}

.Pedestal-View-Number {
  visibility: hidden;
}
