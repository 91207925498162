.header {
  padding: 20px;

  display: flex;
  justify-content: space-between;
  gap: 50px;

  &__title {
    display: flex;
    justify-content: start;
    cursor: pointer;
    align-items: center;
    gap: 5px;
    width: 50%;

    font-size: 20px;
    font-weight: 600;
    line-height: 27.28px;
    text-align: left;
    color: #454951;
  }
}
