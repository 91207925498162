.comingSoon {
  font-family: DrukTextWide;
  text-transform: uppercase;
  font-size: 3.8vw;
  text-shadow: rgba(255, 255, 255, 0.45) 0px 0px 8px;
  width: 100%;
  color: #fff;

  height: 100%;
  min-height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 845px) {
    font-size: 7.8vw !important;
    text-shadow: none !important;
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.45));
  }
}
