.buttonGroup {
  display: flex;
  justify-content: center;
  max-width: 346px;
  margin: 0 auto;
  padding: 40px 16px;

  &__content {
    display: flex;
    border-radius: 25px;
    overflow: hidden;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    color: white;
    text-align: center;
    flex: 1;

    background: #2d2f37;

    border-radius: 0px;

    &:first-child {
      border-radius: 25px 0px 0px 25px;
    }

    &:last-child {
      border-radius: 0px 25px 25px 0px;
    }

    @media (max-width: 768px) {
      &:first-child {
        border-radius: 25px;
      }

      &:last-child {
        border-radius: 25px;
      }
    }
  }

  &__active {
    background: linear-gradient(104.01deg, #5b18a5 0%, #831ee9 100%);
  }

  // @media (max-width: 768px) {
  //   display: none;
  // }
}

.page {
  display: flex;
  flex-direction: column;
  gap: 50px;
  background: linear-gradient(0deg, #252939 0%, #11131a 100%);
  overflow: hidden;
  position: relative;

  font-family:
    'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
    sans-serif !important;

  span {
    font-weight: 800;
  }

  &__mobile {
    display: none;
    @media (max-width: 845px) {
      display: block;
    }
  }

  &__desktop {
    display: block;
    @media (max-width: 845px) {
      display: none;
    }
  }
}

.filter {
  width: 100%;
  display: flex;
  justify-content: center;

  &__item {
    background: #2d2f37;
    color: white;
    padding: 8px 25px;
    cursor: pointer;
    white-space: nowrap;
    height: 20px;
    font-weight: 700;
    min-width: 86px;

    &:first-child {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:nth-child(2) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }

    &:only-child {
      border-radius: 5px;
    }
  }

  &_active {
    background: linear-gradient(
      142deg,
      rgba(74, 37, 143, 1) 0%,
      rgba(101, 48, 194, 1) 37%,
      rgba(119, 55, 224, 1) 74%,
      rgba(119, 55, 224, 1) 100%
    );
    color: white;
  }
}

.pedestal {
  width: 100%;
  display: flex;
  justify-content: center;

  &__content {
    // height: 550px;
    // min-width: 1035px;
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  &__rankings {
    padding-top: 50px;
    display: flex;
    gap: 80px;
    width: 100%;
    position: relative;
    font-family: 'Open Sans';

    @media (max-width: 1400px) {
      gap: 20px;
    }
  }
}

.pedestalCard {
  &__prizes {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 43px;
      background: rgba(0, 0, 0, 0.27);
      padding-bottom: 5px;
    }
  }

  &__prize {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: white;
    position: relative;
    z-index: 1;
    padding-bottom: 5px;

    &_count {
      text-transform: uppercase;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
    }

    &_title {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    div {
      text-transform: uppercase;
      font-size: 32px;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      text-align: center;
    }

    span {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &__bottomTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 100px;

    div {
      margin: 0px 50px;
      width: 100%;
      font-weight: 600;
      font-size: 13px;
      color: white;
      text-transform: uppercase;
      // font-size: 32px;
      // text-transform: uppercase;
      // font-weight: 700;
      // text-align: center;
    }
  }
}

.coins {
  &__gold {
    img {
      position: absolute;
      left: 0px;
      top: 250px;
      width: 12%;
      filter: blur(7px);
    }

    @media (max-width: 845px) {
      img {
        left: -10px;
        width: 12%;
        filter: blur(5px);
      }
    }
  }

  &__silver {
    img {
      position: absolute;
      right: 0;
      top: 500px;
      width: 8%;
      filter: blur(7px);
    }

    @media (max-width: 845px) {
      img {
        right: 0;
        width: 60px;
        filter: blur(5px);
      }
    }
  }
}
