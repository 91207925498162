.buttonGroup {
  display: flex;
  justify-content: center;
  max-width: 646px;
  margin: 0 auto;
  padding: 40px 16px;

  &__content {
    display: flex;
    border-radius: 25px;
    overflow: hidden;
    width: 100%;

    @media (max-width: 768px) {
      flex-direction: column;
      gap: 12px;
    }
  }

  &__button {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 16px 20px;
    color: white;
    text-align: center;
    flex: 1;

    background: #2d2f37;

    border-radius: 0px;

    &:first-child {
      border-radius: 25px 0px 0px 25px;
    }

    &:last-child {
      border-radius: 0px 25px 25px 0px;
    }

    @media (max-width: 768px) {
      &:first-child {
        border-radius: 25px;
      }

      &:last-child {
        border-radius: 25px;
      }
    }
  }

  &__active {
    background: linear-gradient(104.01deg, #5b18a5 0%, #831ee9 100%);
  }

  // @media (max-width: 768px) {
  //   display: none;
  // }

  &__title {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__img {
    height: 40px;
    min-width: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }
}
