$primaryColor: #886cff;

@media (max-width: 1530px) {
  .App-horizontal-nav {
    p {
      width: 0;
      overflow: hidden;
    }
  }
}
// @media (max-width: 1485px) {
//   .Streamer-List {
//     justify-content: left !important;
//   }
// }

@media (max-width: 950px) {
  .App-header {
    .App-header-main {
      .App-horizontal-nav {
        height: 50px;
        width: 100%;

        a {
          width: 25px;
        }

        li {
          span {
            visibility: hidden;
            width: 0;
            height: 0;
          }
        }
      }

      .App-bar-right {
        width: 260px !important;
      }
    }

    .Leaderboard-header-button {
      width: 70px !important;

      .Leaderboard-content {
        visibility: hidden;
        width: 0 !important;
        overflow: hidden !important;
      }
    }
  }

  .Content-container {
    h2 {
      font-size: 12vw !important;
    }
  }
}

@media (max-width: 845px) {
  .App-horizontal-nav {
    visibility: hidden;
  }
  .App-footer .Footer-Main .App-logo {
    margin-top: 150px;
  }
  .Footer-Signature {
    margin-top: 100px;
    margin-bottom: 30px;
  }

  .App-header-main {
    width: 100% !important;

    .App-horizontal-nav {
      width: calc(100% - 300px) !important;
      visibility: hidden !important;
    }

    .Leaderboard-header-button {
      visibility: hidden;
      width: 0 !important;
    }
  }

  .App-userbar {
    height: 0;
    width: 0;
    overflow: hidden;
    visibility: hidden;
  }

  .App-userbar-mobile {
    overflow: unset !important;
    visibility: visible !important;
    height: 40px !important;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    border-left: none !important;
    width: 24px !important;
    position: absolute;
    right: 0px;
    padding-right: 20px;

    &__burger {
      width: 24px;
      height: 12px;
    }
  }

  .Leaderboard-Pedestal {
    .Rankings {
      display: flex;
      flex-direction: column;

      .Ranking,
      .Content {
        left: 5px;
        width: calc(100% - 20px) !important;

        .User-info {
          width: calc(100% - 0px) !important;
        }
      }
      .Gold,
      .Silver,
      .Bronze {
        height: 275px !important;
      }
    }
  }

  .Leaderboard-Pedestal-container {
    width: 0;
    height: 0;
    visibility: hidden;
    overflow: hidden;
  }

  .Leaderboard-Pedestal-container-mobile {
    display: flex;
    flex-direction: column;
    height: 400px !important;
    overflow: visible !important;
    overflow: initial !important;
    padding-top: 0 !important;
    visibility: inherit !important;
    width: auto !important;
    .Leaderboard-Pedestal {
      max-width: 100%;
      min-width: 100% !important;
    }

    .Leaderboard-btn {
      width: 100%;
      width: calc(100% - 10px) !important;

      display: flex;
      justify-content: center;

      a {
        width: 60% !important;
      }
    }
  }

  .makeStyles-card-18,
  a {
    max-width: calc(100% - 0px);
  }

  .Video-case {
    width: 100%;
  }

  .Headline {
    height: 130px !important;
    padding: 50px 0 !important;

    h3 {
      width: 100% !important;
      font-size: 44px !important;
      padding: 0 !important;
      text-align: center !important;
      height: unset !important;
      text-shadow: none !important;
      filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.6));
    }

    h1 {
      width: 100% !important;
      font-size: 44px !important;
      padding: 0 !important;
      text-align: center !important;
    }

    p {
      width: 100% !important;
      padding: 0px 0 !important;
      text-align: center !important;
    }
  }
  .Background-img {
    visibility: hidden;
  }

  .Vip-Rewards-List {
    padding: 0 !important;
    .Vip-Reward-Entry {
      width: 90% !important;
    }
  }

  .No-direction {
    flex-direction: unset !important;
  }

  .Leaderboard-page-ped {
    width: 0 !important;
    height: 0 !important;
    visibility: hidden !important;
    overflow: hidden !important;
  }

  .Vip-rewards-Tab-items {
    display: flex;
    justify-content: center;
    flex-direction: column !important;

    .Reward {
      width: 100% !important;
      display: flex;
      justify-content: center;
      flex-direction: column;
      margin-top: 50px;
    }
  }

  .Landing-heading h4 {
    font-size: 48px !important;
  }

  .hero {
    height: 1100px !important;
    overflow: hidden;
  }

  /** New design **/
  .Footer-Main {
    padding-top: 100px !important;
  }

  .Streamer-List {
    display: grid !important;
    flex-direction: column;
    justify-content: center !important;
    margin-top: -50px;
  }

  .Main-heading-larger {
    font-size: 7.8vw !important;
  }

  .Main-heading-largest {
    font-size: 12.8vw !important;
  }

  .Main-heading {
    font-size: 7.8vw !important;
    text-shadow: none !important;
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.45));
  }

  .Heading-Landing {
    margin-top: 50px;
  }

  .Landing-Coins {
    .Gold {
      img {
        left: -10px;
        top: -20px;
        width: 12% !important;
        filter: blur(2px) !important;
      }
    }
    .Silver {
      img {
        right: 0;
        top: 250px;
        width: 60px;
      }
    }

    img {
      width: 40%;
      filter: blur(5px);
      position: absolute;
    }
  }

  .App-contents-container
    .Leaderboard-Pedestal-container
    .Leaderboard-Pedestal
    .Rankings
    .Ranking {
    height: 225px !important;
    margin: 0 !important;
  }

  .App-contents-container
    .Leaderboard-Pedestal-container
    .Leaderboard-Pedestal
    .Rankings
    .Ranking
    .Content {
    min-width: calc(100% - 50px) !important;
    max-width: calc(100% - 50px) !important;
    left: 25px;

    .User-info-container-gold {
      position: block;
      // height: 275px;
    }

    .User-icon {
      left: 0 !important;
      top: -25px !important;
      width: 80px !important;
      height: 80px !important;

      img {
        width: 80px !important;
        height: 80px !important;
      }
    }
  }

  .Leaderboard-Pedestal-container {
    width: 100%;
    display: flex;
    justify-content: center;

    .Leaderboard-Pedestal {
      height: 550px;
      min-width: 1035px;
      display: flex;
      flex-direction: column;

      .Rankings {
        padding-top: 40px;
        display: flex;
        width: 100%;
        position: relative;
        padding-bottom: 10px;
        font-family: 'Open Sans';

        .Ranking {
          min-width: 370px;
          height: 315px;
          margin: 0;
          border-radius: 10px;
          position: relative;

          .Content {
            position: absolute;
            bottom: 0;
            min-width: 370px;
            box-shadow:
              0px 2px 1px -1px rgba(0, 0, 0, 0.2),
              0px 4px 4px 0px rgba(0, 0, 0, 0.14),
              0px 4px 7px 0px rgba(0, 0, 0, 0.12);

            .User-icon {
              position: absolute;
              width: 150px;
              height: 150px;
              left: 0px;
              top: -75px;
              border-radius: 50%;
              box-shadow:
                0px 2px 1px -1px rgba(0, 0, 0, 0.2),
                0px 4px 4px 0px rgba(0, 0, 0, 0.14),
                0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important;

              img {
                height: 150px;
                width: 150px;
                border-radius: 50%;
              }
            }

            .User-info-container {
              position: static;
              height: 0px;
            }

            .User-info-container-silver {
              height: 430px;
            }

            .User-info-container-gold {
              // height: 500px;
            }
            .User-info {
              position: absolute;
              width: 100%;
              height: 100%;
              display: flex;
              flex-direction: column-reverse;
              align-items: flex-end;
              align-text: center;
              bottom: 0;

              span,
              h4 {
                text-transform: uppercase;
                color: white;
                font-weight: 700;
                width: 100%;
                text-align: center;
              }

              h4 {
                font-size: 36px;
                font-weight: 700;
                padding: 5px 0 5px 0;
              }

              .Druk {
                font-size: 50px;
                font-family: DrukTextWide;
                text-shadow: rgba(0, 0, 0, 0.45) 0px 0px 8px;
                padding-top: 3px;
              }

              span {
                font-weight: 600;
                font-size: 13px !important;
              }

              .leaderboard-username {
                text-transform: uppercase;
                font-size: 18px;
                margin-top: 10px;
                background: rgba(0, 0, 0, 0.27);
                height: 40px;
                color: white;
                font-weight: 700;
                width: 100%;
                text-align: center;
                border-bottom-right-radius: 10px;
                border-bottom-left-radius: 10px;
                padding: 5px 0;
              }

              .Username {
                text-transform: uppercase;
                font-size: 32px !important;
                width: 255px;
                text-transform: uppercase;
                color: white;
                font-weight: 700;
                text-align: center;
              }
            }
          }

          .Content:hover {
            position: absolute;
            bottom: 0;
            //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.4), 0px 14px 14px 0px rgba(0, 0, 0, 0.24), 0px 4px 17px 0px rgba(0, 0, 0, 0.22) !important;
          }
        }
        .Gold {
          height: 500px;
          border-radius: 10px;
          background: #3c4155;
          background: rgb(60, 65, 85);
          background: linear-gradient(
            180deg,
            #3b4055 0%,
            rgba(91, 81, 52, 1) 20%,
            #ffce00 100%
          );
          box-shadow: rgba(255, 221, 0, 0.2) 0 0 30px !important;
          cursor: pointer;
        }
        .Gold:hover {
          background: linear-gradient(
            180deg,
            #3b4055 0%,
            rgb(108, 95, 60) 20%,
            #ffdd00 100%
          );
        }
        .Silver {
          height: 430px;
          background: rgb(82, 82, 82);
          border-radius: 10px;
          background: linear-gradient(
            180deg,
            rgba(60, 65, 85, 1) 0%,
            rgba(82, 82, 82, 1) 20%,
            rgba(194, 194, 194, 1) 100%
          );
          box-shadow: rgba(60, 65, 85, 0.5) 0 0 30px !important;
          cursor: pointer;
        }
        .Silver:hover {
          border-radius: 10px;
          background: linear-gradient(
            180deg,
            rgba(60, 65, 85, 1) 0%,
            rgb(94, 91, 91) 20%,
            rgb(210, 210, 210) 100%
          );
        }
        .Bronze {
          height: 390px;
          background: rgb(64, 49, 36);
          border-radius: 10px;
          background: linear-gradient(
            180deg,
            rgba(60, 65, 85, 1) 0%,
            rgba(168, 127, 91, 1) 20%,
            rgba(64, 49, 36, 1) 100%
          );
          box-shadow: rgba(82, 61, 44, 0.5) 0 0 30px !important;
          cursor: pointer;
        }
        .Bronze:hover {
          border-radius: 10px;
          background: linear-gradient(
            180deg,
            rgba(60, 65, 85, 1) 0%,
            rgb(179, 126, 79) 20%,
            rgb(82, 61, 44) 100%
          );
        }
      }
    }
  }

  .Heading-Landing {
    margin-top: 80px !important;
  }

  .Heading-Leaderboard-sub {
    margin: 30px 0 !important;
  }

  .PreviousLeaderboard {
    padding-left: 10px;
  }

  /** Leaderboard */
  .App-contents-container
    .Leaderboard-Pedestal-container
    .Leaderboard-Pedestal {
    height: 315px !important;
  }

  .Small-Large-btn {
    // width: 30%;
    height: 50px !important;
  }

  .Pedestal-View-Number {
    display: inline-flex;
    width: 100%;
    justify-content: center;
    margin-top: 50px;
    visibility: visible !important;

    div {
      border-radius: 50%;
      background: #0c0e12;
      width: 50px;
      height: 50px;
      font-weight: 900;
      font-size: 32px;
      padding: 5px 5px 0 5px;
      margin: 20px;
      cursor: pointer;
    }

    .First {
      border: 1px solid #f8ce46;
      color: #f8ce46;
    }

    .Second {
      border: 1px solid #bfbfbf;
      color: #bfbfbf;
    }

    .Third {
      border: 1px solid #c38142;
      color: #c38142;
    }

    .First-Active {
      color: #0c0e12;
      background: #f8ce46;
    }
    .Second-Active {
      color: #0c0e12;
      background: #bfbfbf;
    }
    .Third-Active {
      color: #0c0e12;
      background: #c38142;
    }
  }
  .Druk {
    text-shadow: none !important;
    filter: drop-shadow(0px 0px 8px rgba(0, 0, 0, 0.6));
  }

  .CardHover-video,
  .CardHover-video:hover {
    width: 300px !important;
    min-width: 300px !important;
    max-width: 300px !important;
    .Card {
      width: 300px !important;
      min-width: 300px !important;
      max-width: 300px !important;
    }
  }

  .makeStyles-card-18,
  .jss18 {
    width: 350px !important;
    min-width: 350px !important;
    max-width: 350px !important;
  }
  .Leaderboard-container ul {
    width: 580px !important;
  }
  .Leaderboard-container .Heading {
    width: 590px !important;
  }

  /** VIP **/

  .Vip-Reward-Entry {
    width: 90% !important;
    margin-left: 5% !important;

    .Icon {
      width: 90px !important;
    }

    .Text {
      white-space: nowrap;
      display: flex;
      flex-direction: column;
      font-size: 15px !important;

      .reward {
        justify-content: left !important;
      }
    }

    .Action {
      display: flex;
      justify-content: right;
      padding-right: 20px;
      text-transform: uppercase;

      button {
        max-width: 70px;
        min-height: 50px;
        margin-top: 0 !important;
        min-width: 80px !important;
      }
    }
  }
  .Vip-Rewards-List-container {
    padding-top: 0px !important;
  }
  .Current-Tier-Claim {
    button {
      height: 50px !important;
      padding: 0 50px !important;
      font-weight: 700 !important;
      font-size: 18px;
    }
  }

  .Current-Tier-Container .Current-Tier .Current-Tier-Info {
    display: flex !important;
    flex-direction: column;
    max-width: 100%;

    .Current-Tier-Image {
      width: unset !important;
      margin-bottom: 50px;
    }

    .Current-Tier-Content {
      max-width: 100%;
      width: 100% !important;

      .Current-Tier-Title {
        h4 {
          text-align: center !important;
        }
      }
      .Current-Tier-Description {
        p {
          text-align: center !important;
          margin-bottom: 50px;
          width: 90% !important;
          padding-left: 5%;
        }
      }
    }
  }

  .Tier-Progress-container {
    width: 0 !important;
    height: 0 !important;
    overflow: hidden !important;
    visibility: hidden !important;
  }
  /** END OF VIP **/

  /** CASINO **/

  .Casino-coin1 {
    height: 400px;
    position: absolute;
    top: 60px !important;
    left: -360px !important;
  }

  .Casino-coin2 {
    height: 250px !important;
    position: absolute;
    top: 650px !important;
    right: -100px !important;
  }

  .Casino-card-Container {
    padding-top: 120px !important;
  }

  .Casino-card {
    width: 360px !important;
  }

  .PartnerList .Benefit {
    margin-left: calc(10% + 45px) !important;
  }
  /** END OF CASINO **/
}
