@import '../../../assets/CSS/variables.scss';

.block {
  padding: 100px 0;
  height: auto;

  background:
    url('../../../assets/images/raffles/rafflesBg.png') no-repeat center,
    linear-gradient(
      305.75deg,
      #191031 0%,
      #291468 31%,
      #462195 56%,
      #7637e0 77%,
      #9021ff 95%
    );

  @media (max-width: 768px) {
    padding: 40px 0;
    height: 836px;

    background:
      url('../../../assets/images/raffles/rafflesMobileBg.png') no-repeat center,
      linear-gradient(
        305.75deg,
        #191031 0%,
        #291468 31%,
        #462195 56%,
        #7637e0 77%,
        #9021ff 95%
      );
  }

  &__content {
    padding: 0px 16px;

    display: flex;
    flex-direction: column;
    gap: 63px;
    height: 100%;
  }

  &__text {
    color: white;
  }

  &__subtitle {
    font-size: 28px;
    line-height: 33.6px;
    font-weight: 800;
    text-align: left;

    margin-bottom: 12px;

    @media (min-width: 768px) {
      font-size: 40px;
      line-height: 48px;
    }
  }

  &__title {
    font-weight: 800;
    font-size: 64px;
    line-height: 76.8px;
    text-align: left;

    @media (min-width: 768px) {
      font-size: 120px;
      line-height: 144px;
    }
  }

  &__steps {
    &_desktop {
      display: flex;
      justify-content: space-between;
      position: relative;
      height: 100%;
      flex: 1 1 100%;

      @media (max-width: 768px) {
        display: none;
      }
    }

    &_mobile {
      display: none;
      justify-content: space-between;
      flex-direction: column;
      position: relative;
      height: 100%;
      flex: 1 1 100%;

      @media (max-width: 768px) {
        display: flex;
      }

      &__arrowFirst {
        top: 5%;
        left: 7%;
        position: absolute;

        @media (min-width: 500px) {
          left: 3%;
        }
      }

      &__arrowSecond {
        top: 47%;
        left: 12%;
        position: absolute;

        @media (min-width: 500px) {
          left: 8%;
        }
      }
    }

    @media (min-width: 768px) {
      height: auto;
    }
  }
}

.infoItem {
  display: flex;
  gap: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-width: 250px;

  @media (max-width: 768px) {
    position: relative;
    z-index: 5;
    flex-direction: row;
  }

  &_first {
    &::before {
      content: '';
      position: absolute;
      top: -40px;
      left: 135px;

      // width: 190%;
      width: calc(40% + 30px);
      height: 100px;
      border: dashed 2px white;
      border-color: white transparent transparent transparent;
      border-radius: 50%/100px 100px 0 0;

      @media (max-width: 1064px) {
        width: calc(38% + 30px);
      }

      @media (max-width: 900px) {
        width: calc(39% + 30px);
        left: 125px;
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &_second {
    &::before {
      content: '';
      position: absolute;
      bottom: 35px;
      left: 58%;

      width: calc(33% + 30px);
      height: 100px;
      border: dashed 2px white;
      border-color: transparent transparent white transparent;
      border-radius: 50%/0 0 100px 100px;

      @media (max-width: 1064px) {
        left: 60%;
        width: calc(28.5% + 30px);
      }

      @media (max-width: 900px) {
        left: 61%;
        width: calc(26.5% + 30px);
      }

      @media (max-width: 768px) {
        display: none;
      }
    }
  }

  &__icon {
    padding: 22px;
    background: white;
    border-radius: 20px;
    border-bottom-left-radius: 0px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__text {
    text-transform: capitalize;
    color: white;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: center;
  }
}
