@import 'src/assets/CSS/variables.scss';

.panel {
  h4 {
    color: white;
    font-size: 50px;
    font-weight: 900;
    text-align: center;
  }

  &__action {
    display: flex;
    justify-content: end;
  }

  &__table {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__header {
    display: flex;
    flex-direction: column;
    gap: 5px;
    margin-bottom: 20px;

    button {
      align-self: self-end;
    }
  }
}
