@import 'src/assets/CSS/variables.scss';

.links {
  display: flex;
  flex-direction: column;
  gap: 8px;

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
  }

  &__item {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }

  &__subTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    align-items: center;
  }
}
