.PurchaseModal {
  display: flex;
  flex-direction: column;
  justify-content: center;

  .ItemImage {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  h5 {
    padding-top: 10px;
    font-size: 16px;
    font-weight: 800;
    width: 100%;
    text-align: center;
  }

  p {
    padding: 25px 10px;
    width: 100%;
    text-align: center;
  }

  .ItemCost {
    width: 100%;
    display: flex;
    justify-content: center;

    button {
      margin: 50px 20px;
    }
  }
}

div.Buy {
  svg {
    padding-right: 10px;
  }
}
