.container {
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
  height: 100%;
}

.wrapper {
  padding: 0px 16px;
}
