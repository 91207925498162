.Reel-container {
  width: 33%;
  min-height: 400px;
  display: flex;
  justify-content: center;

  span {
    padding-top: 150px;
    font-weight: 600;
    color: white;
  }
}

.App-highlights-reel-mobile {
  height: 0;
  overflow: hidden;
  visibility: hidden;

  .Highlight-mobile-pagination {
    display: inline-flex;
    width: 100%;
    justify-content: center;

    div {
      width: 8%;
      height: 10px;
      background: #3b3b3b;
      border-radius: 5px;
      margin: 10px;
      cursor: pointer;
    }
    div.Active {
      background: #ffffff;
    }
  }
}

.App-highlights-reel {
  width: 100%;
}

.reel-bordered {
  border-bottom: 1px solid #b67fff !important;
}

.Multiplier-highlight-clip {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  .Clip {
    margin: 100px 0;
    border: 2px solid rgba(255, 255, 255, 0.4);
    box-sizing: content-box;
    width: 500px;
    height: 280px;

    video {
      width: 100%;
    }
  }

  .video-container {
    position: relative;
    padding-bottom: 56.25%;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .Unpadded {
    padding-top: 0 !important;
  }

  .First {
    border: 2px solid rgb(218, 165, 32);
    width: 530px;
    height: 300px;
  }
}

.Top-Viewer-Buys-container {
  width: 100%;

  .Top-Viewer-Buys {
    width: 1200px;
    display: inline-flex;
    justify-content: space-between;

    .Top-Viewer-Buy {
      width: 30%;
      height: 220px;
      border-radius: 25px;
      background-size: contain;
      margin-top: 10px;
      cursor: pointer;
    }
    .Top-Viewer-Buy-Main {
      width: 33%;
      height: 240px;
      margin-top: 0;
    }
    .Top-Viewer-Buy-Main:hover {
      width: 34%;
      height: 240px;
      margin-top: 0;
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.4),
        0px 14px 14px 0px rgba(0, 0, 0, 0.24),
        0px 4px 17px 0px rgba(0, 0, 0, 0.22) !important;
    }
    .Top-Viewer-Buy:first-child:hover {
      width: 31%;
      margin-left: -1%;
    }
    .Top-Viewer-Buy:last-child:hover {
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.4),
        0px 14px 14px 0px rgba(0, 0, 0, 0.24),
        0px 4px 17px 0px rgba(0, 0, 0, 0.22) !important;
      width: 31%;
      margin-right: -1%;
    }
  }
}
