.Current-Tier-Container {
  width: 100%;
  display: flex;
  margin: 50px 0px;
  justify-content: center;

  .Current-Tier {
    width: 700px;
    max-width: 100%;

    .Current-Tier-Info {
      display: inline-flex;
      width: 700px;
      max-width: 100%;

      .Current-Tier-Image {
        width: 200px;
        display: flex;
        justify-content: center;

        img {
          width: 100px;
        }
      }

      .Current-Tier-Content {
        width: 500px;
        color: white;

        .Current-Tier-Title {
          font-size: 42px;
          text-transform: uppercase;
          font-style: italic;

          h4 {
            width: 100%;
            text-align: left;
          }
        }

        .Current-Tier-Description {
          p {
            width: 100%;
            text-align: left;
            font-size: 14px;
            padding-top: 10px;
          }
        }
      }
    }
  }
  .Current-Tier-Claim {
    width: 700px;
    max-width: 100%;
    padding-top: 25px;
    display: flex;
    justify-content: center;
  }
}

.Vip-Rewards-List-container {
  padding-top: 100px;
}

.Tier-Progress-container {
  margin-top: 50px;
  width: 100%;
  justify-content: center;
  display: inline-flex;

  .Tier-Progression {
    max-width: 1040px;
    width: 100%;
    justify-content: center;
    display: inline-flex;

    .Progression-Current-Tier,
    .Progression-Next-Tier {
      width: 120px;
      white-space: nowrap;
      ul {
        list-style: none;
        color: white;

        img {
          width: 60px;
        }

        li:nth-child(2) {
          margin-top: 10px;
        }
      }
    }

    .Progression-Progress {
      max-width: 800px;
      width: 100%;
      padding-top: 5px;
      display: flex;

      flex-direction: column;
      justify-content: center;

      .Progression-Progress-bar {
        max-width: 800px;
        width: 100%;
        padding-top: 25px;
      }

      .Progression-Progress-value {
        max-width: 800px;
        width: 100%;
        padding-top: 25px;

        color: white;
      }

      progress[value]::-webkit-progress-bar {
        background-color: #3d4255;
        border-radius: 40px;
      }

      progress[value]::-webkit-progress-value {
        border-radius: 40px;
      }

      progress::-moz-progress-bar,
      progress::-webkit-progress-value {
        background: rgb(119, 55, 224);
        background: linear-gradient(
          142deg,
          rgba(119, 55, 224, 1) 0%,
          rgba(119, 55, 224, 1) 25%,
          rgba(131, 56, 221, 1) 75%,
          rgba(148, 58, 215, 1) 100%
        );
      }

      progress {
        width: 95%;
        height: 15px;
        text-align: center;
        color: rgb(119, 55, 224);
        color: linear-gradient(
          142deg,
          rgba(119, 55, 224, 1) 0%,
          rgba(119, 55, 224, 1) 25%,
          rgba(131, 56, 221, 1) 75%,
          rgba(148, 58, 215, 1) 100%
        );
        border: none;
        border-radius: 10px;
        background: #3d4255;
        -webkit-appearance: none;
        border-radius: 20px;
        -moz-appearance: none;
        appearance: none;
      }

      progress[value]::-webkit-progress-value {
        background-image:
          -webkit-linear-gradient(
            top,
            rgba(255, 255, 255, 0.25),
            rgba(0, 0, 0, 0.25)
          ),
          -webkit-linear-gradient(
              142deg,
              rgba(119, 55, 224, 1) 0%,
              rgba(119, 55, 224, 1) 25%,
              rgba(131, 56, 221, 1) 75%,
              rgba(148, 58, 215, 1) 100%
            );

        background-size:
          0px 20px,
          100% 100%,
          100% 100%;
      }
    }
  }
}

.VIP-icon {
  svg {
    width: 50px !important;
    height: 50px !important;
  }
}

.VIP {
  font-family:
    'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
    sans-serif !important;
  color: #606780;
  // margin: 0px 10px;

  .Rules-button {
    height: 25px;
    color: white;

    .Rules-btn {
      padding-left: 10px;
      color: white !important;
    }
  }

  .Vip-rewards-Tabs {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    button {
      height: 40px;
      width: 20%;
      background: none;
      color: #1a1d26;
    }

    button.Active {
      background: #1a1d26;
      color: white;
      border-radius: 15px;
    }
  }

  .Vip-rewards-Tab-items {
    display: flex;
    justify-content: center;
    flex-direction: row;

    .Reward {
      width: 33%;
      display: flex;
      justify-content: center;
      flex-direction: column;

      .Icon {
        width: 100%;
        display: flex;
        justify-content: center;

        padding: 10px 0;

        img {
          width: 130px;
        }
      }

      .Requirement {
        width: 100%;
        display: flex;
        justify-content: center;

        span {
          color: #676b71;
          font-weight: 400;
          text-transform: uppercase;
          padding: 10px 0;
        }
      }

      .Amount {
        width: 100%;
        display: flex;
        justify-content: center;
        color: white;
        padding: 10px 0;
      }

      .ClaimButton {
        width: 100%;
        display: flex;
        justify-content: center;
        padding: 10px 0;
      }
    }
  }

  .Vip-rewards-Tabs,
  .Vip-rewards-Tab-items {
    width: calc(100% - 100px);
    min-height: 50px;
    margin-left: 50px;
    margin-bottom: 20px;
    background: #404558 !important;
    border-radius: 10px;
  }

  .Warning {
    width: 100%;
    padding-bottom: 25px;

    .Reset-warning {
      width: 100%;
      text-transform: uppercase;
      font-size: 21px;
      color: white;
      font-weight: 800;
      font-weight: 400;
    }

    .Reset-warning-highlight {
      text-transform: uppercase;
      font-size: 21px;
      //color: $primaryColor;
      font-weight: 800;
    }
  }

  .VIP-container {
    padding: 25px;

    p,
    span {
      color: white;
      font-weight: 600;
    }
    h2 {
      font-size: 4vw;
      font-weight: 900;
    }

    h2 {
      text-transform: uppercase;
      font-optical-sizing: auto;
      -webkit-text-fill-color: transparent;
      background: -webkit-linear-gradient(#6c19ff, #6f20ffff);
      background-clip: border-box;
      -webkit-background-clip: text;
      text-align: center;
      text-shadow: #5b00ff 0px 0px 16px;
    }
  }

  .Vip-Rewards-List {
    padding: 15px calc(50% - 500px);
    width: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;

    .Vip-Reward-Entry {
      list-style: none;
      margin: 30px 0 0 0px;
      font-family:
        'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
        sans-serif;
      font-weight: 400;
      font-size: 1rem;
      line-height: 1.5;
      height: 100px;
      border-radius: 20px !important;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      //border: 1px solid #2a2e38;
      background: #2f333a;
      color: #9e9e9e;
      width: 100%;
      max-width: 1000px;
      box-shadow:
        0px 2px 1px -1px rgba(0, 0, 0, 0.2),
        0px 4px 4px 0px rgba(0, 0, 0, 0.14),
        0px 4px 7px 0px rgba(0, 0, 0, 0.12);

      .Icon {
        width: 120px;
        height: 80px;

        img {
          padding: 15px 20px;
          width: 50px;
          height: 50px;
        }
      }

      .Text {
        width: calc(100% - 250px);
        display: flex;
        font-weight: 600;

        .wager {
          width: 50%;
          display: flex;
          justify-content: left;
          color: white;
        }

        .reward {
          width: 50%;
          display: flex;
          justify-content: right;
        }
      }

      .Action {
        padding-left: 20px;
        padding-top: 15px;
        width: 130px;
        height: 60px;

        button {
          height: 35px;
        }
      }
    }
  }
}
