.top {
  width: 100%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  gap: 50px;

  &__rankings {
    padding: 5px 40px;
    display: flex;
    position: relative;
    padding-bottom: 10px;
    font-family: 'Open Sans';
  }
}

.ranking {
  min-width: 100%;
  height: 300px;
  border-radius: 10px;
  position: relative;

  &__content {
    position: absolute;
    bottom: 0;
    width: 100%;
    // min-width: 370px;
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 4px 4px 0px rgba(0, 0, 0, 0.14),
      0px 4px 7px 0px rgba(0, 0, 0, 0.12);

    .User-info-container {
      position: relative;
      height: 390px;
    }

    .User-info-container-silver {
      height: 430px;
    }

    .User-info-container-gold {
      height: 100%;
      // height: 500px;
    }
    .User-info {
      position: absolute;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      align-items: flex-end;
      align-text: center;
      bottom: 0;

      span,
      h4 {
        text-transform: uppercase;
        color: white;
        font-weight: 700;
        width: 100%;
        text-align: center;
      }

      h4 {
        font-size: 36px;
        font-weight: 700;
        padding: 5px 0 5px 0;
      }

      .Druk {
        font-size: 50px;
        font-family: DrukTextWide;
        text-shadow: rgba(0, 0, 0, 0.45) 0px 0px 8px;
        padding-top: 3px;
      }

      span {
        font-weight: 600;
        font-size: 13px !important;
      }

      .leaderboard-username {
        text-transform: uppercase;
        font-size: 18px;
        margin-top: 10px;
        background: rgba(0, 0, 0, 0.27);
        height: 40px;
        color: white;
        font-weight: 700;
        width: 100%;
        text-align: center;
        border-bottom-right-radius: 10px;
        border-bottom-left-radius: 10px;
        padding: 5px 0;
      }

      .Username {
        text-transform: uppercase;
        font-size: 32px !important;
        width: 255px;
        text-transform: uppercase;
        color: white;
        font-weight: 700;
        text-align: center;
      }
    }

    &:hover {
      position: absolute;
      bottom: 0;
      //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.4), 0px 14px 14px 0px rgba(0, 0, 0, 0.24), 0px 4px 17px 0px rgba(0, 0, 0, 0.22) !important;
    }
  }

  &__userIcon {
    position: absolute;
    left: 0;
    top: -25px;
    height: 80px;
    width: 80px;

    border-radius: 50%;
    box-shadow:
      0px 2px 1px -1px rgba(0, 0, 0, 0.2),
      0px 4px 4px 0px rgba(0, 0, 0, 0.14),
      0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important;

    img {
      height: 100%;
      width: 100%;
      border-radius: 50%;
    }
  }

  &_gold {
    height: 300px;
    border-radius: 10px;
    background: #3c4155;
    background: rgb(60, 65, 85);
    background: linear-gradient(
      180deg,
      #3b4055 0%,
      rgba(91, 81, 52, 1) 20%,
      #ffce00 100%
    );
    box-shadow: rgba(255, 221, 0, 0.2) 0 0 30px !important;
    cursor: pointer;
  }

  &_silver {
    height: 300px;
    background: rgb(82, 82, 82);
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      rgba(60, 65, 85, 1) 0%,
      rgba(82, 82, 82, 1) 20%,
      rgba(194, 194, 194, 1) 100%
    );
    box-shadow: rgba(60, 65, 85, 0.5) 0 0 30px !important;
    cursor: pointer;
  }

  &_bronze {
    height: 300px;
    background: rgb(64, 49, 36);
    border-radius: 10px;
    background: linear-gradient(
      180deg,
      rgba(60, 65, 85, 1) 0%,
      rgba(168, 127, 91, 1) 20%,
      rgba(64, 49, 36, 1) 100%
    );
    box-shadow: rgba(82, 61, 44, 0.5) 0 0 30px !important;
    cursor: pointer;
  }
}

.user {
  position: relative;
  height: 100%;

  .User-info-container-silver {
    height: 430px;
  }

  .User-info-container-gold {
    height: 100%;
    // height: 500px;
  }

  &__info {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column-reverse;
    align-items: flex-end;
    align-text: center;
    bottom: 0;

    > span,
    > h4 {
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      width: 100%;
      text-align: center;
    }

    > h4 {
      font-size: 36px;
      font-weight: 700;
      padding: 5px 0 5px 0;
    }

    > span {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &__username {
    font-size: 50px;
    font-family: DrukTextWide;
    text-shadow: rgba(0, 0, 0, 0.45) 0px 0px 8px;
    padding: 3px 0px;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    width: 100%;
    text-align: center;
  }

  &__prizes {
    text-transform: uppercase;
    font-size: 18px;
    margin-top: 10px;
    background: rgba(0, 0, 0, 0.27);
    height: 40px;
    color: white;
    font-weight: 700;
    width: 100%;
    text-align: center;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
    padding: 5px 0;
  }

  &__title {
    text-transform: uppercase;
    font-size: 32px;
    width: 255px;
    text-transform: uppercase;
    color: white;
    font-weight: 700;
    text-align: center;
  }
}

.pedestalCard {
  &__prizes {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    position: relative;

    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 43px;
      background: rgba(0, 0, 0, 0.27);
      border-radius: 0px 0px 10px 10px;
      padding-bottom: 5px;
    }
  }

  &__prize {
    display: flex;
    flex-direction: column;
    gap: 5px;
    color: white;
    position: relative;
    z-index: 1;
    padding-bottom: 5px;

    &_count {
      text-transform: uppercase;
      font-size: 32px;
      text-transform: uppercase;
      font-weight: 700;
      text-align: center;
    }

    &_title {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &__bottom {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    div {
      text-transform: uppercase;
      font-size: 32px;
      text-transform: uppercase;
      color: white;
      font-weight: 700;
      text-align: center;
    }

    span {
      font-weight: 600;
      font-size: 13px;
    }
  }

  &__bottomTitle {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    // gap: 100px;

    div {
      margin: 0px 50px;
      width: 100%;
      font-weight: 600;
      font-size: 13px;
      color: white;
      text-transform: uppercase;
      // font-size: 32px;
      // text-transform: uppercase;
      // font-weight: 700;
      // text-align: center;
    }
  }
}

.selection {
  display: inline-flex;
  width: 100%;
  justify-content: center;
  visibility: visible !important;

  div {
    border-radius: 50%;
    width: 50px;
    height: 50px;
    font-weight: 900;
    font-size: 32px;
    padding: 5px 5px 0 5px;
    margin: 20px;
    cursor: pointer;
  }

  &__first {
    border: 1px solid #f8ce46;
    color: #f8ce46;

    &_active {
      color: #0c0e12;
      background: #f8ce46;
    }
  }

  &__second {
    border: 1px solid #bfbfbf;
    color: #bfbfbf;

    &_active {
      color: #0c0e12;
      background: #bfbfbf;
    }
  }

  &__third {
    border: 1px solid #c38142;
    color: #c38142;

    &_active {
      color: #0c0e12;
      background: #c38142;
    }
  }
}

.Leaderboard-Pedestal-container-mobile {
  display: flex;
  flex-direction: column;
  height: 400px !important;
  overflow: visible !important;
  overflow: initial !important;
  padding-top: 0 !important;
  visibility: inherit !important;
  width: auto !important;
  .Leaderboard-Pedestal {
    max-width: 100%;
    min-width: 100% !important;
  }

  .Leaderboard-btn {
    width: 100%;
    width: calc(100% - 10px) !important;

    display: flex;
    justify-content: center;

    a {
      width: 60% !important;
    }
  }
}
