.SlipToggle {
  width: 70px;
  height: 70px;
  background: #c31a0f;
  border: 3px solid white;
  border-radius: 50%;
  position: absolute;
  right: 25px;
  bottom: 25px;
  cursor: pointer;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.4),
    0px 14px 14px 0px rgba(0, 0, 0, 0.24),
    0px 4px 17px 0px rgba(0, 0, 0, 0.22) !important;

  svg {
    padding: 10px;
    path {
      fill: white;
      stroke: white;
    }
  }
}
.SlipToggle:hover {
  background: red;
}

.Battles-contents {
  width: 100%;
  overflow-y: scroll;
  -ms-overflow-style: none; /* Hide the scrollbar for MS Edge */
  scrollbar-width: none; /* Hide the scrollbar for Mozilla Firefox */
  height: 100%;
  display: inline-flex;
}

.Battle-Wagers {
  width: 0;
  transition: 1.3s;
  overflow: hidden;

  .Wagers-content {
    width: 100%;
    height: 100%;

    svg {
      cursor: pointer;

      path {
        fill: white;
      }
    }

    svg:hover {
      path {
        fill: #c31a0f;
      }
    }
  }

  .Wagers-slots {
    height: calc(100% - 360px);
    display: flex;
    justify-content: center;

    .Wager-slot {
      border-radius: 10px;
      width: 280px;
      height: 290px;
      margin: 10px 10px 0 10px;
      //border: 1px solid #2e3240;
      display: flex;
      flex-direction: column;
      background: transparent;
      padding: 0;
      color: white;

      .Wager-image {
        width: 100%;
        height: 220px;
        background-size: cover;
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        background-position-y: -40px;
      }

      .Wager-content {
        width: calc(100% - 10px);
        background: #202431;
        border-bottom-left-radius: 0.5rem;
        border-bottom-right-radius: 0.5rem;
        padding-top: 10px;
        padding-right: 10px;
        height: 80px;
        text-transform: uppercase;

        .Wager-game {
          width: 100%;
          text-align: left;
          padding-left: 15px;
          h6 {
            padding: 3px 0 3px 0;
            font-size: 16px !important;
          }
        }

        .Wager-metrics {
          display: inline-flex;
          width: calc(100% - 10px);
          padding-left: 10px;
          justify-content: space-between;

          h6 {
            margin: 0px;
            font-family:
              'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica',
              'Arial', sans-serif;
            line-height: 1.5;
            padding: 0.25rem;
            font-size: 14px;
            font-weight: 600;
            white-space: nowrap;
            padding-top: 5px;
          }

          h4 {
            padding-top: 5px;
            margin: 0px;
            font-family:
              'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica',
              'Arial', sans-serif;
            font-weight: 400;
            line-height: 1.5;
            color: #9e9e9e;
            font-size: 12px;
            white-space: nowrap;
          }
        }
      }
    }
  }

  .Wagers-slot-count {
    max-height: 50px;
    width: calc(100% - 30px);
    margin-left: 10px;
    color: #9e9e9e;
    display: flex;
    border-bottom: 1px solid #2e3240;
    padding: 0 0 10px 0;
    font-weight: 600;
    font-size: 14px;
  }

  .Wagers-payout-rates {
    height: 60px;
    padding-top: 10px;

    ul {
      width: calc(100% - 20px);
      list-style: none;
      display: inline-flex;
      justify-content: space-between;
      color: white;
      padding: 0 10px;
    }

    ul:first-child {
      padding: 5px 0;
      font-weight: 800;
    }

    ul:nth-child(2) {
      color: #9e9e9e;
    }
  }

  .Wagers-input {
    height: 60px;
    ont-family:
      'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
      sans-serif;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.4375em;
    box-sizing: border-box;
    cursor: text;
    -moz-box-align: center;
    align-items: center;
    position: relative;
    border-radius: 8px;
    border-color: transparent;
    transition: 0.3s ease-in-out;

    input {
      border: 1px solid #2e3240;
      background: #282b38;
      border-radius: 5px;
      padding: 15px;
      color: white;
      width: calc(100% - 50px);
    }
    input:focus {
      outline: none;
    }
  }

  .Wagers-buttons {
    height: 60px;
    justify-content: center;
    display: flex;
    flex-direction: column;
    width: 60%;
    margin-left: 20%;
    button {
      height: 30px;
      width: 100%;
      font-weight: 500;
      padding: 10px;
      background: #d14d47;
    }

    button:first-child {
      background: rgb(138, 73, 243);
      background: linear-gradient(
        180deg,
        rgba(138, 73, 243, 1) 0%,
        rgba(138, 72, 242, 1) 100%
      );
    }
  }
}

.Battles-contents-slips {
  width: calc(100% - 400px);
  padding: 10px;
}

.Battle-Wagers-visible {
  width: 400px;
  touch-action: manipulation;
  display: inline-flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  //border-left: 1px solid #2e3240;
  z-index: 10;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 0px 1rem;
  transition: 1.3s;
}

.Battle-Wagers-visible-content {
  position: absolute;
  right: 0;
  top: 90px;
  background: #424659;
  width: 0px;
  height: calc(100% - 90px);
  transition: 1.3s;
  overflow: hidden;
}

.Battle-Wagers-visible-content-visible {
  width: 360px;
  height: calc(100% - 90px);
  transition: 1.3s;
}

.Wagers-toggle-hidden {
  visibility: hidden;
}

.Slot-Battles-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Slot-Battles-content-container {
  max-width: 1300px;
  width: 100%;

  .table-header {
    border: none !important;
  }
}

.Slot-battles {
  width: 100%;
  min-height: 375px;
  display: flex;
  flex-wrap: wrap;
  padding-left: 50px;
  justify-content: left;

  span.Disclaimer {
    font-family:
      'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
      sans-serif;
    padding: 30px 10px;
    color: white;
    font-weight: 600;
    font-size: 16px;
  }

  .Battle {
    max-width: 535px;
    height: 365px;
    margin: 50px 34px;
    display: flex;
    flex-direction: column;
    gap: 1rem;
    //border: 1px solid #2e3240;
    background: #424659;
    border-radius: 0.5rem;

    .Battle-header {
      width: 100%;
      display: inline-flex;
      height: 50px;

      .Streamer {
        width: 50%;
        display: inline-flex;
        position: relative;

        img {
          width: 80px;
          height: 80px;
          left: -35px;
          top: -35px;
          border-radius: 50%;
          border: 1px solid #2e3240;
          position: absolute;
        }

        h3 {
          padding: 18px 0 0 65px;
          color: white;
          font-size: 21px;
          font-family: DrukTextWide;
        }
      }
      .Battle-Date {
        width: 50%;
        display: inline-flex;
        justify-content: right;

        h6 {
          color: white;
          font-size: 14px;
          font-weight: 400;
          padding: 23px;
        }

        .Live {
          font-weight: 800;
          color: #7ff998;
          text-transform: uppercase;
          font-family: DrukTextWide;
        }
      }
    }

    .Battle-slots {
      max-width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: left;

      .Slot {
        width: calc(50% - 24px);
        height: 50px;
        margin: 10px 10px;
        display: inline-flex;
        gap: 1rem;
        border: 1px solid #7f87a8;
        background: #606680;
        border-radius: 0.5rem;

        .Slot-title {
          width: calc(100% - 90px);
          display: inline-flex;

          img {
            margin: 5px;
            width: 50px;
            height: 40px;
            border-radius: 5px;
            //border: 1px solid #2e3240;
          }

          h3 {
            padding: 15px 0;
            color: white;
            font-weight: 400;
            margin: 0px;
            font-family: 'Open Sans';
            line-height: 1.5;
            text-align: start;
            font-size: 14px;
            max-width: 75%;
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
          }
        }

        .Slot-votes {
          width: 80px;
          margin: 0px 0px 0px auto;
          padding: 13px 0;

          span {
            font-family: 'Open Sans';
            line-height: 1.5;
            font-size: 16px;
            color: white;
            font-weight: 800;
          }
        }
      }
    }
  }

  .Battle-slots-votable {
    .Slot {
      cursor: pointer;
    }
    .Slot:hover {
      background: #2b3138;
    }
  }

  .Selected {
    background: #313649 !important;
    border: 1px solid #6f7694 !important;
  }

  .Wagered {
    background: rgba(255, 212, 0, 0.35) !important;
    border: 1px solid rgba(255, 212, 0, 1) !important;
  }

  .Winning {
    background: #43585d !important;
    border: 1px solid #7ff998 !important;
  }
}

.Slot-battle-top-payouts-container {
  width: 100%;
  display: flex;
  justify-content: center;
}

.Slot-battle-top-payouts {
  max-width: 1000px;
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  max-height: 600px;
  overflow-x: hidden;
  overflow-y: scroll;
  background: linear-gradient(0deg, #11131a 0%, #252939 100%);
  border-radius: 10px;
  border: 3px solid rgb(126, 138, 179);
  font-family: 'Open Sans' !important;

  ul {
    width: 100%;
    height: 40px;
    list-style: none;
    display: inline-flex;
    color: #fff;
    border: 1px solid #2a2e38;
    font-weight: 400;
    margin: 0px;
    line-height: 1.3;
    text-align: start;
    font-size: 16px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding: 15px 10px 10px 10px;
    border-bottom: 1px solid rgba(126, 138, 179, 0.43);

    li {
      width: 300px;
      display: inline-flex;
      justify-content: center;
    }

    li:last-child {
      min-width: 230px;
    }
  }

  ul.Headers {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    color: white;
    font-weight: 800;
  }

  ul:last-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
  }

  .Slot-Icon {
    img {
      margin: -5px 5px;
      width: 25px;
      height: 35px;
    }
  }

  .Payout-user {
    width: 100%;
    display: inline-flex;

    img {
      width: 35px;
      height: 35px;
      margin: -5px 10px;
      border-radius: 50%;
    }
  }
}

.Slot-Battles-Content-100 {
  width: 100%;
}

.Active-filter-slot-battles {
  width: 100%;
  display: inline-flex;
  justify-content: center;

  div {
    background: none;
    border: none;
    color: white;
    padding: 8px 25px;
    cursor: pointer;
    white-space: nowrap;
    height: 15px !important;
    font-weight: 700;
    border-right: 3px solid white;
    border-radius: 0;
    text-transform: uppercase;
  }

  div:last-child {
    border: none;
  }
  div.Active {
    background: none;
    color: #856dfb;
  }

  .table-header {
    border: none !important;
  }
}
