.Challenges {
  font-family:
    'Noto Sans', 'Inter', 'Poppins', 'Montserrat', 'Helvetica', 'Arial',
    sans-serif !important;

  span {
    font-weight: 800;
  }

  .Challenge-Tip {
    font-size: 18px;
    font-weight: 600;
    padding: 15px 4px;
    text-align: center;
    width: calc(100% - 10px);
    display: inline-flex;
    justify-content: center;
    background: rgb(81, 50, 205);
    background: linear-gradient(
      119deg,
      rgba(81, 50, 205, 1) 0%,
      rgba(163, 96, 247, 1) 100%
    );
    border-radius: 5px;
    color: white;

    span {
      padding-right: 5px;
    }
  }
}

.Challenge-list-header {
  width: calc(100% - 20px);
  height: 70px;
  display: inline-flex;

  .Bet-size-filter {
    width: 50%;
    display: flex;
    justify-content: right;
  }
}

.Challengers {
  width: 100%;
  padding-top: 50px !important;

  .Challenger {
    width: 100%;
    height: 120px;
    display: inline-flex;
    justify-content: center;
    cursor: pointer;
    margin-bottom: 30px;

    .Challenger-Image {
      width: 120px;
      height: 120px;
      z-index: 100;
      img {
        width: 120px;
        border-radius: 50%;
        box-shadow:
          0 2px 1px -1px #0003,
          0 4px 4px 0 #00000024,
          0 4px 7px 0 #0000001f !important;
      }
    }

    .Challenger-content {
      width: 400px;
      margin: 10px 0 0 -40px;
      height: 100px;
      display: inline-flex;
      justify-content: space-between;
      border-radius: 10px;

      .Challenger-Name {
        width: 100px;
        padding: 40px 0 0 55px;
        font-size: 16px;
        color: white;
        text-transform: uppercase;
        font-family: DrukTextWide !important;
      }

      .Challenger-Values {
        width: calc(100% - 100px);
        display: inline-flex;
        justify-content: right;
        ul {
          list-style: none;
          padding: 25px 20px;

          li {
            text-transform: uppercase;
            color: white;
            font-size: 12px;
          }
        }
        span {
          padding: 0;
          margin: 0;
          font-size: 24px;
        }
      }
    }

    .Gold {
      background: rgb(60, 65, 85);
      background: linear-gradient(
        -90deg,
        #3b4055 0%,
        rgba(91, 81, 52, 1) 1%,
        #c9a302 100%
      );
      box-shadow:
        0 2px 1px -1px rgba(59, 64, 85, 0.138),
        0 4px 4px 0 rgba(91, 81, 52, 0.15),
        0 4px 7px 0 rgba(255, 206, 0, 0.149) !important;
      width: 700px;
    }

    .Silver {
      background: rgb(60, 65, 85);
      background: linear-gradient(
        -90deg,
        rgba(60, 65, 85, 1) 0%,
        rgba(82, 82, 82, 1) 10%,
        rgba(194, 194, 194, 1) 100%
      );
      box-shadow:
        0 2px 1px -1px rgba(60, 65, 85, 0.138),
        0 4px 4px 0 rgba(82, 82, 82, 0.15),
        0 4px 7px 0 rgba(194, 194, 194, 0.149) !important;
      width: 600px;
    }

    .Bronze {
      background: rgb(60, 65, 85);
      background: linear-gradient(
        90deg,
        rgba(60, 65, 85, 1) 0%,
        rgba(168, 127, 91, 1) 1%,
        rgba(64, 49, 36, 1) 100%
      );
      box-shadow:
        0 2px 1px -1px rgba(60, 65, 85, 0.138),
        0 4px 4px 0 rgba(168, 127, 91, 0.15),
        0 4px 7px 0 rgba(64, 49, 36, 0.149) !important;
      width: 500px;
    }
  }
}

.Timed-challenges {
  width: 1200px;
  display: inline-flex;
  justify-content: space-between;

  .TimedChallenge {
    width: 370px;
    height: 238px;
    display: inline-flex;
    background: #424659;
    border-radius: 20px;
    overflow: hidden;

    .Challenge-image {
      width: 178px;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
      background-size: contain;
    }

    .Challenge-content {
      width: 210px;
      color: white;

      .Challenge-Title {
        padding: 25px 0;
        font-style: italic;
        text-transform: uppercase;
      }

      .Challenge-Info {
        display: inline-flex;
        padding: 10px 0 35px 0;
        font-size: 14px;
        width: 100%;
        justify-content: center;

        ul {
          list-style: none;
          padding: 0 15px;
        }
        ul:first-child {
          border-right: 1px solid rgba(255, 255, 255, 0.24);
        }
        ul:nth-child(2) {
          border-right: 1px solid rgba(255, 255, 255, 0.24);
        }

        img {
          width: 23px;
          margin-bottom: 5px;
        }
      }
      .Challenge-Time {
        font-size: 32px;
        font-style: italic;
      }
    }
  }
}

.Challenges-list {
  width: 90%;
  margin-left: 5%;

  .Challenge-Info {
    display: inline-flex;
    padding: 25px 0;
    font-size: 14px;
    font-weight: 600;
    width: 100%;
    justify-content: center;

    ul {
      list-style: none;
      padding: 0 15px;
    }
    ul:first-child {
      border-right: 1px solid rgba(255, 255, 255, 0.24);
    }
    ul:nth-child(2) {
      border-right: 1px solid rgba(255, 255, 255, 0.24);
    }

    li {
      display: flex;
      justify-content: center;
    }

    img {
      width: 23px;
      margin-bottom: 5px;
    }
  }

  .Challenge-Play {
    button {
      width: calc(100% - 32px);
      height: 35px;
      border-radius: 35px;
      background: rgb(74, 37, 143);
      background: linear-gradient(
        -142deg,
        rgba(74, 37, 143, 1) 0%,
        rgba(101, 48, 194, 1) 37%,
        rgb(134, 55, 224) 74%,
        rgb(140, 55, 224) 100%
      );
      margin: 0 16px;
    }
  }
}
