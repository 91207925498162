.copy {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 5px;

  width: 400px;
  display: inline-flex;
  padding: 10px;

  input {
    background: #202429;
    width: 100%;
    // width: calc(100% - 100px);
    padding: 10px;
    border-radius: 5px;
    border: none;
    color: #886cff;
  }
  input:focus {
    outline: none;
  }

  &__icon {
    display: flex;
    justify-content: center;
    padding: 5px;
  }
}
