.card {
  box-sizing: border-box;
  max-width: 420px;
  width: 100%;
  background: linear-gradient(
    180deg,
    rgba(47, 49, 58, 1) 0%,
    rgba(27, 29, 39, 1) 100%
  );
  padding: 30px 10px;
  box-shadow:
    0 2px 1px -1px #0003,
    0 4px 4px 0 #00000024,
    0 4px 7px 0 #0000001f !important;
  border-radius: 10px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;

  &__codes {
    display: flex;
    flex-direction: column;
    align-items: start;
    gap: 10px;
  }

  @media (max-width: 768px) {
    width: 100%;
    min-height: 350px;
    max-width: 100%;
  }
}

.code {
  font-size: 23px;
  font-weight: 600;
  line-height: 15px;
  text-shadow: #00000073 0 0 8px;
  color: white;

  &__link {
    color: #8075f5;
    text-decoration: none;
  }
}

.bonus {
  color: #fff;
  display: flex;
  align-items: center;
  gap: 5px;
  font-size: 16px;
  font-weight: 600;

  &__icon {
    color: #8075f5;
  }
}
