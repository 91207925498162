.badge {
  display: flex;
  align-items: center;
  gap: 8px;

  &__point {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    display: inline-block;
  }

  &__text {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
  }
}
