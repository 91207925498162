.Casino-card-Container {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 50px;
}

.Casino-card {
  background: rgb(47, 49, 58);
  background: linear-gradient(
    180deg,
    rgba(47, 49, 58, 1) 0%,
    rgba(27, 29, 39, 1) 100%
  );
  padding: 40px 0;
  box-shadow:
    0 2px 1px -1px #0003,
    0 4px 4px 0 #00000024,
    0 4px 7px 0 #0000001f !important;
  border-radius: 10px;
  width: 420px;

  p.Code {
    color: white;
    font-size: 16px;
  }

  img {
    height: 45px;
  }
}

.PartnerList {
  font-family: 'Open Sans';

  .ReferralCode {
    width: 80%;
    margin: 20px 10%;
    display: flex;
    justify-content: space-around;
    font-size: 23px;
    font-weight: 600;
    text-align: left;
    line-height: 15px;
    text-shadow: rgba(0, 0, 0, 0.45) 0px 0px 8px;

    span {
      padding-left: 75px;
      color: rgba(255, 255, 255, 0.85);
    }
    .Code {
      color: #8075f5;
    }
    svg {
      color: #8075f5;
    }

    .Code:hover {
      color: #fff;
    }
  }

  .Benefit {
    width: 80%;
    display: flex;
    justify-content: left;
    margin-bottom: 5px;
    margin-left: calc(10% + 67px);
    font-weight: 600;
    color: white;
    font-size: 16px;

    svg {
      margin-left: 20px;
      margin-right: 7px;
      color: #8075f5;
    }
  }
  .gradient {
    background: radial-gradient(
      circle at 50% 140%,
      rgba(136, 108, 255, 0.63) 20%,
      rgba(255, 255, 255, 0) 50%
    );
    position: absolute;
    bottom: 0px;
    z-index: 1;
    border-radius: inherit;
    width: 100%;
    height: 70%;
    pointer-events: none;
  }
}

.ReferralCard {
  min-width: calc(25% - 55px);

  img {
    width: 200px;
  }
}

.Text-Content-card {
  background: rgb(47, 49, 58);
  background: linear-gradient(
    180deg,
    rgba(47, 49, 58, 1) 0%,
    rgba(27, 29, 39, 1) 100%
  );
  padding: 40px;
  box-shadow:
    0 2px 1px -1px #0003,
    0 4px 4px 0 #00000024,
    0 4px 7px 0 #0000001f !important;
  border-radius: 10px;
  width: 85%;

  p {
    font-size: 16px;
    font-weight: 600;
    color: white !important;
    text-align: left !important;
    line-height: 20px;
  }

  img {
    height: 45px;
  }
}
