.link {
  display: flex;
  align-items: center;
  gap: 5px;

  font-size: 18px;
  font-weight: 500;
  line-height: 24.55px;
  text-align: left;
  color: #ffffff80;
}
