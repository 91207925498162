@import 'src/assets/CSS/variables.scss';

.entries {
  display: flex;
  flex-direction: column;
  gap: 38px;

  &__title {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;

    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: $secondaryGray;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__list {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(3, 1fr);
    grid-column-gap: 12px;
    grid-row-gap: 12px;

    @media (max-width: 1000px) {
      grid-template-columns: repeat(3, 1fr);
    }

    @media (max-width: 768px) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media (max-width: 560px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
