.Bonus-hunts-container {
  display: inline-flex;
  width: 100% !important;
  justify-content: center;

  .Bonus-hunts-content-container {
    width: 100%;
    max-width: 1400px;
  }
  .Bonus-hunts-stat-container {
    display: inline-flex;
    width: 70%;
    justify-content: center;
    background: #101115;
    border-radius: 10px;
    height: 142px;
    padding-top: 10px;

    .Bonus-hunts-stat {
      width: 33%;
      height: 90px;
      margin-top: 20px;
      display: flex;
      justify-content: left;
      //background: #1b1d27;
      border-right: 1px solid #727da1;

      .Stat-icon {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        background: #7f74f2;
        margin: 0;
        margin-left: 30px;
        padding: 0;

        img {
          padding: 0;
          margin: 10px;
          width: 50px;
          height: 70px;
        }
      }

      ul {
        padding: 10px 20px;
        list-style: none;
      }

      li.Value {
        margin-top: 10px;
        width: 100%;
        color: white;
        text-align: left;
        font-size: 21px;
        font-weight: 800;
        white-space: nowrap;
        font-family: 'Open Sans';
      }

      li.Heading {
        font-size: 14px;
        color: #d0d0d0;
        text-transform: uppercase;
        font-weight: 600;
        text-align: left;
      }
    }
    .Bonus-hunts-stat:last-child {
      border-right: none;
    }
  }
}

.Bonus-hunts-list-filter {
  width: calc(100% - 20px);
  margin: 25px 25px;
  height: 70px;
  display: inline-flex;
  justify-content: right;

  .Bonus-hunt-filter {
    width: 300px;
    display: flex;
  }
}

.Bonus-hunt-view {
  width: 100%;

  .bonus-hunt-return {
    width: 100%;
    height: 40px;
    display: flex;
    padding: 25px 0;
    justify-content: left;

    a {
      padding: 0;
      width: 150px;
      text-decoration: none !important;
      display: inline-flex;
      justify-content: space-between;
    }

    span {
      color: white;
      font-size: 18px;
      padding-top: 10px;
    }

    svg {
      color: white;
      margin: 8px 0;
    }
  }
}

.Bonus-hunt-overview {
  width: 100%;

  .overview-header {
    width: 100%;
    height: 50px;
    display: inline-flex;

    .header-option {
      height: 35px;
      display: flex;
      -moz-box-align: center;
      align-items: center;
      gap: 0.5rem;
      cursor: pointer;
      background-color: #1b1d27;
      padding: calc(0.5rem - 1px) 15px;
      border-width: 1px;
      border-style: solid;
      border-image: none;
      border-color: #2a2e38;
      color: #d0d0d0;
      font-weight: 600 !important;
      font-size: 14px;

      svg {
        path {
          fill: #d0d0d0;
        }
      }
    }

    .header-option:first-child {
      border-radius: 0.5rem 0px 0px;
    }

    .header-option:nth-child(2) {
      border-radius: 0px 0.5rem 0px 0px;
    }

    .Active-option {
      border-color: #bd314b;
    }
  }
  .overview-stats {
    width: calc(100% - 0px);
    cursor: pointer;
    //background-color: #1b1d27;
    //padding: 0.75rem;
    border: none;
    color: #d0d0d0;
    font-weight: 600 !important;
    padding: 0 !important;
    margin: 0 !important;

    p {
      width: 100%;
      text-align: left;
    }
    p.Stat-header {
      padding: 15px 0 0 0px;
    }

    .Value {
      color: #d0d0d0;
      text-align: center !important;
      width: 100%;
      font-size: 18px;
      font-weight: 800;
      padding-top: 10px;
    }
    .Heading {
      font-size: 18px;
      font-weight: 400 !important;
      text-align: center !important;
      color: rgba(255, 255, 255, 0.55);
      padding-top: 10px;
      width: 100%;
    }
  }
}

.Bonus-hunt-search {
  width: 100%;
  height: 120px;
  //border-bottom: 1px solid #2a2e38;
  .search-input {
    width: 100%;
    height: 45px;
    //border: 1px solid #2a2e38;
    border-radius: 5px;
    margin: 37px 0;
    background: #313648;
    display: inline-flex;

    img {
      height: 17px;
      width: 50px;
      padding: 15px 0px;
    }

    input {
      width: calc(100% - 50px);
      background: none;
      border: none;
      color: white;
    }

    input:focus {
      outline: none;
    }
  }

  .search-input:hover {
    //background: #202429;
    cursor: text;
  }
}

.HuntsPagination {
  color: white !important;

  .MuiTablePagination-actions {
    button {
      border-radius: 5px !important;
      margin-right: 10px;
    }
  }

  button {
    background: none !important;
    border: none !important;
    box-shadow: none !important;
  }

  .Mui-disabled {
    background-color: transparent;
    color: rgba(255, 255, 255, 0.4) !important;
  }
}

.Guess-the-balance {
  padding-top: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;

  h3,
  span {
    width: 100%;
    text-align: left;
    padding: 5px 10px;
  }

  .No-padding {
    padding: 0;
  }

  span {
    font-size: 13px;
  }

  .Button-set {
    margin: 10px;
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid #886cff;
    border-radius: 5px;
    padding: 0;

    button {
      margin: 0;
      background: transparent;
      display: flex;
      flex-direction: column;
      width: 25%;
      border-right: 1px solid #886cff;
      border-radius: 0;

      span {
        padding: 0px;
        text-align: center;
      }

      span:first-child {
        font-size: 14px;
        font-weight: 700;
        text-transform: uppercase;
      }

      span:last-child {
        font-size: 14px;
        font-weight: 700;
        border-right: none;
      }

      span:nth-child(2) {
        font-size: 9px;
        font-weight: 500;
        text-transform: uppercase;
      }
    }
    button:hover {
      margin: 0;
      background: #886cff;
    }
  }
}

.GreenedCard {
  border: 2px solid #80fb9a !important;
}

.Bonus-hunts-guessing-state {
  border: 2px solid #828fb9;
  background: linear-gradient(
    180deg,
    rgba(37, 41, 55, 1) 0%,
    rgba(0, 0, 0, 0.1) 100%
  );
  border-radius: 20px;
  margin: 30px 0;
  padding: 20px 0;
  h4 {
    font-size: 18px;
    text-transform: uppercase;
    padding: 0 40px;
  }
}
