.hero {
  overflow: hidden;
  padding: 266px 0px 266px 0px;
  position: static;
  background:
    url('../../../assets/images/rafflesBg.png') center no-repeat,
    linear-gradient(
      125.75deg,
      #9021ff 5%,
      #7637e0 23%,
      #462195 44%,
      #291468 69%,
      #191031 100%
    );

  @media (max-width: 500px) {
    background:
      url('../../../assets/images/rafflesBgMobile.png') center no-repeat,
      linear-gradient(
        125.75deg,
        #9021ff 5%,
        #7637e0 23%,
        #462195 44%,
        #291468 69%,
        #191031 100%
      );
  }

  @media (max-width: 768px) {
    position: relative;
    padding: 80px 0px 290px 0px;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;

    max-width: 1200px;
    margin: 0 auto;
    width: 100%;

    @media (max-width: 768px) {
      justify-content: center;
      text-align: center;
      position: static;
    }
  }

  &__titleBlock {
    position: relative;
    z-index: 5;
  }

  &__title {
    margin-bottom: 8px;
    color: #ffffff;
    font-size: 120px;
    font-weight: 800;
    line-height: 144px;
    text-align: left;

    @media (max-width: 768px) {
      text-align: center;

      font-size: 88px;
      line-height: 105.6px;
    }
  }

  &__subtitle {
    color: #ffffff;
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;

    margin-bottom: 40px;

    @media (max-width: 768px) {
      font-size: 20px;
      line-height: 27.28px;
    }
  }

  &__btnContainer {
    display: flex;
    justify-content: start;
    align-items: center;
    gap: 24px;

    @media (max-width: 950px) {
      button {
        &:first-child {
          order: 3;
        }
      }
    }

    @media (max-width: 580px) {
      flex-direction: column;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    gap: 16px;
  }

  &__banner {
    position: absolute;
    right: -10%;

    @media (max-width: 768px) {
      bottom: 0;
      right: 0;
      object-fit: contain;
      width: 100%;
      height: 90%;
    }

    @media (max-width: 580px) {
      height: 70%;
    }
  }
}
