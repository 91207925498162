.modal {
  display: flex;
  flex-direction: column;
  gap: 30px;

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;
    margin-bottom: 12px;
  }

  &__btnContainer {
    display: flex;
    justify-content: end;
  }
}
