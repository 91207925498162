@media (max-width: 725px) {
  .Bonus-hunts-container {
    display: flex;
    flex-direction: column;

    .Bonus-hunts-stat-container {
      width: 100% !important;
      max-width: 605px !important;
      overflow-x: scroll;
      justify-content: left;

      .Bonus-hunts-stat {
        min-width: 200px !important;

        ul {
          padding: 0 !important;
        }

        .Stat-icon {
          height: 45px !important;
          margin: 10px 10px 0 15px !important;
          width: 45px !important;

          img {
            height: 35px !important;
            margin: 5px !important;
            width: 25px !important;
          }
        }
      }
    }
  }

  .Bonus-hunts-stat {
    width: 100% !important;
  }

  .mobile-content {
    width: 100% !important;
  }
}

@media (max-width: 600px) {
  .Bonus-hunts-list-filter {
    height: 200px;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
    width: 100% !important;

    .Bonus-hunt-filter,
    .css-2m9kme-MuiFormControl-root,
    .css-1mzc8 {
      width: 90% !important;
    }
  }
}
