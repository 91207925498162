@import '../../../assets/CSS/variables.scss';

.block {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__icon {
    width: 200px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
  }

  &__desc {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: left;
    color: #ffffff80;
  }

  &__links {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
    background: $primaryBlack;
    border: 1px solid $primaryBlack;
    padding: 12px;

    width: 48px;
    height: 48px;
  }
}
