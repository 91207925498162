@media (max-width: 1085px) {
  .To-Row {
    display: flex !important;
    flex-direction: column !important;
  }

  .Account-settings-container-half {
    width: calc(100% - 20px) !important;
  }
}
