.Leaderboard-text-elements {
  h4,
  p,
  span {
    color: white;
    font-weight: 600;
  }
  h2 {
    font-size: 4vw;
    font-weight: 600;
  }
  h4 {
    font-size: 2.5vw;
    font-weight: 600;
  }

  span,
  h2 {
    text-transform: uppercase;
    font-optical-sizing: auto;
    -webkit-text-fill-color: transparent;
    background: -webkit-linear-gradient(#6c19ff, #6f20ffff);
    background-clip: border-box;
    -webkit-background-clip: text;
    text-align: center;
    text-shadow: #5b00ff 0px 0px 16px;
  }
}
.Leaderboard-container {
  padding: 50px;

  ul {
    width: 780px;
    justify-content: space-between;
    display: inline-flex;
    list-style: none;
    color: #b9afca;
    background: #242836;
    border-radius: 0px;
    content: '';
    height: 30px;
    left: 0;
    top: 0;
    padding: 15px 10px 10px 10px;
    border-bottom: 1px solid rgba(126, 138, 179, 0.43);

    li {
      text-align: center;
      width: 25%;
      font-weight: 400;
      font-size: 21px;
      color: white;

      a:hover {
        background-color: purple;
      }

      a {
        -webkit-transition: all 0.5s;
        transition: all 0.5s;
        height: 100%;
        width: 103%;
        display: block;
      }

      p {
        //border: 1px solid #b9afca;
        border-radius: 50%;
        -moz-border-radius: 50%;
        -webkit-border-radius: 50%;
        display: inline-block;
        line-height: 32px;
        text-align: center;
        width: 32px;
        font-weight: 800;
        color: #fff !important;
      }
    }

    li:first-child {
      width: 50px;
    }
  }
  ul:first-child {
    max-height: 40px;
    height: 40px;
    overflow: hidden;
    background: #1e2028 !important;
    font-weight: 400;
    padding: 0;
    text-transform: uppercase;
    font-size: 13px;
    padding: 5px;
  }

  .Heading {
    font-weight: 800;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    width: 790px;
    padding-top: 10px;
    color: white;
    line-height: 35px;
    font-weight: 800;
    li {
      font-size: 16px !important;
    }
  }
}

.Leaderboard-container-Less-padding {
  padding: 15px;
}

.Leaderboard-filter {
  width: calc(100% - 20px);
  margin: 5px 25px;
  height: 70px;
  display: inline-flex;
  justify-content: right;

  .Board-filter {
    width: 300px;
    display: flex;
  }
}

.Leaderboard-subheading {
  text-transform: uppercase;
  font-weight: 600 !important;

  span {
    color: white !important;
  }
}

.PreviousLeaderboard {
  width: 100%;
  max-width: 800px;
  display: inline-flex;
  justify-content: left;
  color: #61677e !important;
  padding-bottom: 20px;

  span {
    font-weight: 600 !important;
  }

  img {
    padding-right: 10px;
  }
}
