@import '../../../assets/CSS/variables.scss';

.card {
  padding: 32px;
  border-radius: 24px;
  background: $primaryGray;
  display: flex;
  flex-direction: column;
  gap: 8px;
  color: white;

  &__titleBlock {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__title {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  &__text {
    color: #ffffff80;
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }
}
