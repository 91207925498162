.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 15px;
  flex-direction: column;
  height: 300px;
  width: 100%;
}

.text {
  font-size: 20px;
  font-weight: 600;
  color: white;
}

.loading {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 50%;
  background:
    radial-gradient(farthest-side, #856efc 94%, #0000) top/8px 8px no-repeat,
    conic-gradient(#0000 30%, #856efc);
  -webkit-mask: radial-gradient(farthest-side, #0000 calc(100% - 8px), #000 0);
  animation: l13 1s infinite linear;
}
@keyframes l13 {
  100% {
    transform: rotate(1turn);
  }
}
