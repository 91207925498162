// Color variables
$primaryGray: #333a53;
$secondaryGray: #454951;
$primaryBlack: #202431;
$secondaryBlack: #1b1d27;

$primaryGreen: #52f164;
$primaryRed: #f73030;
$primaryPurple: #856efc;
$primaryYellow: #e6df65;
