.page {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 50px;

  &__heading {
    width: calc(100% - 20px);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 10px;
    font-weight: 900;
    font-size: 32px;

    h3 {
      width: 100%;
      color: #fff;
    }

    h4 {
      width: 100%;
      text-shadow: #b67fff 0px 0px 4px;
      color: #b67fff;
      text-transform: uppercase;
    }

    h2 {
      width: 100%;
      color: #fff;
    }

    span {
      width: 100%;
      color: #b67fff;
    }
  }

  &__title {
    filter: drop-shadow(0px 0px 8px rgba(255, 255, 255, 0.45));
    font-family: DrukTextWide;
    font-size: 3.8vw;
    text-shadow: #ffffff73 0 0 8px;
    text-transform: uppercase;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 400;
    color: #9a9a9a;
    padding: 10px 0;
  }

  &__mobile {
    display: none;

    @media (max-width: 845px) {
      display: block;
      width: 100%;
    }
  }

  &__desktop {
    display: block;

    @media (max-width: 845px) {
      display: none;
    }
  }
}

.pedestal {
  width: 100%;
  display: flex;
  justify-content: center;

  &__content {
    // height: 550px;
    // min-width: 1035px;
    display: flex;
    flex-direction: column;
    gap: 100px;
  }

  &__rankings {
    padding-top: 50px;
    display: flex;
    gap: 80px;
    width: 100%;
    position: relative;
    font-family: 'Open Sans';

    @media (max-width: 1400px) {
      gap: 20px;
    }
  }
}

.pedestalMobile {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  gap: 50px;
  // height: 400px;

  .Leaderboard-btn {
    width: 100%;
    width: calc(100% - 10px) !important;

    display: flex;
    justify-content: center;

    a {
      width: 60% !important;
    }
  }

  .Leaderboard-Pedestal {
    height: 550px;
    min-width: 1035px;
    display: flex;
    flex-direction: column;

    .Rankings {
      padding-top: 40px;
      display: flex;
      gap: 80px;
      width: 100%;
      position: relative;
      padding-bottom: 10px;
      font-family: 'Open Sans';

      @media (max-width: 1400px) {
        gap: 20px;
      }

      .Ranking {
        min-width: 370px;
        height: 515px;
        // margin: 5px 40px;
        border-radius: 10px;
        position: relative;

        .Content {
          position: absolute;
          bottom: 0;
          min-width: 370px;
          box-shadow:
            0px 2px 1px -1px rgba(0, 0, 0, 0.2),
            0px 4px 4px 0px rgba(0, 0, 0, 0.14),
            0px 4px 7px 0px rgba(0, 0, 0, 0.12);

          .User-icon {
            position: absolute;
            width: 150px;
            height: 150px;
            left: 105px;
            top: -75px;
            border-radius: 50%;
            box-shadow:
              0px 2px 1px -1px rgba(0, 0, 0, 0.2),
              0px 4px 4px 0px rgba(0, 0, 0, 0.14),
              0px 4px 7px 0px rgba(0, 0, 0, 0.12) !important;

            img {
              height: 150px;
              width: 150px;
              border-radius: 50%;
            }
          }

          .User-info-container {
            position: relative;
            height: 390px;
          }

          .User-info-container-silver {
            height: 430px;
          }

          .User-info-container-gold {
            height: 100%;
            // height: 500px;
          }
          .User-info {
            position: absolute;
            width: 100%;
            height: 100%;
            display: flex;
            flex-direction: column-reverse;
            align-items: flex-end;
            align-text: center;
            bottom: 0;

            span,
            h4 {
              text-transform: uppercase;
              color: white;
              font-weight: 700;
              width: 100%;
              text-align: center;
            }

            h4 {
              font-size: 36px;
              font-weight: 700;
              padding: 5px 0 5px 0;
            }

            .Druk {
              font-size: 50px;
              font-family: DrukTextWide;
              text-shadow: rgba(0, 0, 0, 0.45) 0px 0px 8px;
              padding-top: 3px;
            }

            span {
              font-weight: 600;
              font-size: 13px !important;
            }

            .leaderboard-username {
              text-transform: uppercase;
              font-size: 18px;
              margin-top: 10px;
              background: rgba(0, 0, 0, 0.27);
              height: 40px;
              color: white;
              font-weight: 700;
              width: 100%;
              text-align: center;
              border-bottom-right-radius: 10px;
              border-bottom-left-radius: 10px;
              padding: 5px 0;
            }

            .Username {
              text-transform: uppercase;
              font-size: 32px !important;
              width: 255px;
              text-transform: uppercase;
              color: white;
              font-weight: 700;
              text-align: center;
            }
          }
        }

        .Content:hover {
          position: absolute;
          bottom: 0;
          //box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.4), 0px 14px 14px 0px rgba(0, 0, 0, 0.24), 0px 4px 17px 0px rgba(0, 0, 0, 0.22) !important;
        }
      }
      .Gold {
        height: 500px;
        border-radius: 10px;
        background: #3c4155;
        background: rgb(60, 65, 85);
        background: linear-gradient(
          180deg,
          #3b4055 0%,
          rgba(91, 81, 52, 1) 20%,
          #ffce00 100%
        );
        box-shadow: rgba(255, 221, 0, 0.2) 0 0 30px !important;
        cursor: pointer;
      }
      .Gold:hover {
        background: linear-gradient(
          180deg,
          #3b4055 0%,
          rgb(108, 95, 60) 20%,
          #ffdd00 100%
        );
      }
      .Silver {
        height: 430px;
        background: rgb(82, 82, 82);
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(60, 65, 85, 1) 0%,
          rgba(82, 82, 82, 1) 20%,
          rgba(194, 194, 194, 1) 100%
        );
        box-shadow: rgba(60, 65, 85, 0.5) 0 0 30px !important;
        cursor: pointer;
      }
      .Silver:hover {
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(60, 65, 85, 1) 0%,
          rgb(94, 91, 91) 20%,
          rgb(210, 210, 210) 100%
        );
      }
      .Bronze {
        height: 390px;
        background: rgb(64, 49, 36);
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(60, 65, 85, 1) 0%,
          rgba(168, 127, 91, 1) 20%,
          rgba(64, 49, 36, 1) 100%
        );
        box-shadow: rgba(82, 61, 44, 0.5) 0 0 30px !important;
        cursor: pointer;
      }
      .Bronze:hover {
        border-radius: 10px;
        background: linear-gradient(
          180deg,
          rgba(60, 65, 85, 1) 0%,
          rgb(179, 126, 79) 20%,
          rgb(82, 61, 44) 100%
        );
      }
    }
  }
}
