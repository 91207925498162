.Account-settings-container {
  margin: 10px;
  width: 100%;
  border-radius: 15px;
  background-color: #212431;
  box-shadow:
    0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 4px 4px 0px rgba(0, 0, 0, 0.14),
    0px 4px 7px 0px rgba(0, 0, 0, 0.12);
  border: none;

  .Account-integrations {
    display: flex;
    justify-content: left;

    .Integrations-list {
      display: inline-flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;

      .Integration-option {
        display: flex;
        flex-direction: column;
        cursor: pointer;

        .Integration {
          background: #202429;
          height: 35px;
          padding: 10px;
          border: 1px solid #2a2e38;
          margin: 25px;
          border-radius: 10px;
          width: 150px;
          display: flex;
          color: white;

          img {
            width: 35px;
          }

          .Title {
            padding-top: 10px;
          }
          span {
            padding: 15px;
          }
        }
        .Integration:hover {
          background: #282d32;
          border: 1px solid #15191c;
        }

        .Connected {
          border: 1px solid #38ff00 !important;

          .Title {
            padding-top: 0px;
            line-height: 10px;
            display: flex;
            justify-content: space-between;
          }
          span {
            padding: 13px 10px 10px 10px;
          }
          svg {
            padding: 0;
            margin: 0;
            margin-top: 5px;
            color: #38ff00;
          }
        }
        .Integration-heading {
          color: white;
          display: flex;
          margin-left: 35px;
          width: 100%;
          justify-content: center;
          white-space: nowrap;

          span {
            padding-bottom: 20px;
          }
        }
      }
    }
  }

  .Account-inventory {
  }
}

.Account-settings-container-half {
  width: 50% !important;
}

.Profile-general {
  width: 100%;
  display: inline-flex;
}

.ConnectModal {
  h2 {
    width: 100%;
    display: inline-flex;
    line-height: 40px;
    img {
      width: 35px;
    }
  }

  span {
    font-weight: 500;
    font-size: 14px;
    color: #9e9e9e;
    padding: 35px 0px;
  }

  ol {
    padding: 35px 20px;
    li {
      padding: 10px 0;
    }
  }

  .Highlight {
    font-weight: 800;
    color: #886cff;
  }

  .CodeField {
    width: 400px;
    display: inline-flex;
    padding: 10px;
    input {
      background: #202429;
      width: calc(100% - 100px);
      padding: 10px;
      border-radius: 5px;
      border: none;
      color: #886cff;
    }
    input:focus {
      outline: none;
    }

    .Ico {
      width: 10px;
      padding: 5px 0 0 5px;
    }
  }
}

.Profile-Headeing {
  padding: 10px;
  font-weight: 900;
  font-size: 32px;

  h4 {
    text-align: left;
    text-shadow: #b67fff 0px 0px 4px;
    color: #b67fff;
    text-transform: uppercase;
  }
}

.PlayerSettingsForm {
  padding: 0px 20px;
  width: auto !important;

  .Form-input {
    width: 100% !important;

    h5 {
      cursor: pointer;
    }
  }

  .Valid-field {
    background: rgba(0, 255, 34, 0.18) !important;
    border: 1px solid rgba(0, 255, 34, 0.8) !important;
  }
  .Invalid-field {
    background: rgba(255, 0, 0, 0.18) !important;
    border: 1px solid rgba(255, 0, 0, 0.8) !important;
  }
}
