h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: normal;
}

p,
ul,
ol,
figure,
blockquote {
  margin: 0;
  padding: 0;
}

ul,
ol {
  list-style: none;
}

a {
  text-decoration: none;
  color: inherit;
}

button,
input,
textarea,
select {
  font: inherit;
}

table {
  border-collapse: collapse;
  width: 100%;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  padding: 0;
}

textarea {
  resize: vertical;
}
