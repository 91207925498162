@media (max-width: 1450px) {
  .App-highlights-reel-desktop {
    visibility: hidden;
    height: 0;
    overflow: hidden;
  }

  .App-highlights-reel-mobile {
    height: unset;
    overflow: unset;
    visibility: visible;

    .Multiplier-highlight-clip {
      display: inline-grid;
    }
  }

  .Reel-container {
    width: 100%;
  }
}
