.tab {
  color: #fff;
  background: #2d2f37;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  cursor: pointer;

  font-weight: 700;
  min-width: 86px;

  text-align: center;
  white-space: wrap;
  flex: 1;

  outline: none;

  &_selected {
    color: fff;
    border-radius: 0px;

    background: linear-gradient(
      142deg,
      #4a258f,
      #6530c2 37%,
      #7737e0 74%,
      #7737e0
    );

    &:first-of-type {
      border-radius: 5px 0 0 5px;
    }

    &:last-of-type {
      border-radius: 0 5px 5px 0;
    }
  }

  &:first-of-type {
    border-radius: 5px 0 0 5px;
  }

  &:last-of-type {
    border-radius: 0 5px 5px 0;
  }
}

.tabList {
  width: 100%;
  height: 36px;
  display: inline-flex;
}
