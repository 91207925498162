@import '../../../assets/CSS/variables.scss';

.block {
  display: flex;
  flex-direction: column;
  gap: 12px;

  &__icon {
    width: 200px;
  }

  &__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
  }

  &__table {
    border-radius: 4px;
    border: 1px solid #222833;
    background: $primaryBlack;
    color: $secondaryGray;
    padding: 16px 0px;
    display: flex;
    flex-direction: column;
    gap: 16px;

    font-size: 20px;
    font-weight: 600;
    line-height: 27.28px;
  }

  &__iconContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  &__value {
    color: white;
  }

  &__divider {
    background: $secondaryGray;
    height: 1px;
    width: 100%;
  }
}
