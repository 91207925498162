@import '../../assets/CSS/variables.scss';

.btn {
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background: linear-gradient(104.01deg, #5b18a5 0%, #831ee9 100%);
  border: 1px solid #5b18a5;
  border-radius: 40px;
  padding: 10px 16px;
  // height: 57px;
  // text-wrap: nowrap;
  color: white;
  box-shadow: none;

  font-size: 18px;
  font-weight: 800;
  line-height: 24.55px;
  text-align: center;

  &:hover {
    opacity: 0.85;
  }

  &:active {
    opacity: 0.95;
  }
}

.btnOutlined {
  background: transparent;
  border: 1px solid #ffffff1a;
  color: white;
}

.btnWhite {
  background: white;
  color: #5b18a5;
}

.btnOutlinedWhite {
  background: transparent;
  color: white;
  border: 1px solid #fff;
}

.btnUnderline {
  border: none;
  text-decoration: underline;
  border-radius: 0;
  background: none;
}

.btnDisabled {
  opacity: 0.5;

  &:hover {
    opacity: 0.5;
  }
}

.btnClear {
  border: none;
  border-radius: 0;
  background: none;
  display: inline;
  height: auto;
  min-width: 0;
  width: auto;
  padding: 0;
  margin: 0;

  &:hover {
    background: transparent;
  }
}

.btnIcon {
  height: auto;
  width: auto;
  min-width: 0;
  min-height: 0;
  padding: 9px;
  margin: 0px;
  border-radius: 100%;
}

.btnBlack {
  background: #2d2f37;
  color: white;
}
