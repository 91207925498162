@import 'src/assets/CSS/variables.scss';

.entries {
  display: flex;
  flex-direction: column;
  gap: 38px;

  &__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  &__title {
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;

    span {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
      text-align: left;
      color: $secondaryGray;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 48px;
  }

  &__list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
}

.tab {
  &__tabList {
    margin: 0px 0px 50px 0px !important;
  }
}
