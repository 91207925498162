@import '../../assets/CSS/variables.scss';

.footer {
  padding: 40px 16px;
  background: $secondaryBlack;

  @media (max-width: 768px) {
    padding: 40px 16px 24px 16px;
  }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 40px;
    margin-bottom: 24px;

    @media (max-width: 800px) {
      margin-bottom: 40px;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    gap: 24px;
    justify-content: start;
    align-self: start;
  }

  &__logo {
    display: inline-flex;
    justify-content: start;
  }

  &__info {
    max-width: 384px;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.82px;
    text-align: left;
    color: #ffffff99;
  }

  &__social {
    display: flex;
    align-items: center;
    gap: 10px;

    button {
      width: auto;
    }
  }

  &__navigation {
    display: flex;
    align-items: start;
    gap: 90px;
  }

  &__rights {
    font-size: 16px;
    font-weight: 400;
    line-height: 21.82px;
    text-align: left;
    color: #ffffff99;
  }

  &__streams {
    display: flex;
    align-items: end;
    justify-content: end;
    opacity: 0.01;
  }
}

.navigationItem {
  display: flex;
  flex-direction: column;
  gap: 24px;

  &__title {
    font-size: 16px;
    font-weight: 800;
    line-height: 21.82px;
    text-align: left;
    color: #ffffff;
  }

  &__links {
    display: flex;
    flex-direction: column;
    gap: 12px;

    font-size: 16px;
    font-weight: 400;
    line-height: 21.82px;
    text-align: left;
    color: #ffffff99;
  }
}
