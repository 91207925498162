@import '../../assets/CSS/variables.scss';

.raffles {
  background: #202431;

  &__container {
    padding: 150px 16px;

    @media (max-width: 768px) {
      padding: 80px 16px;
    }
  }

  &__blockTitle {
    color: white;
    font-size: 40px;
    font-weight: 800;
    line-height: 48px;
    text-align: left;

    @media (max-width: 768px) {
      text-align: center;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 150px;

    @media (max-width: 768px) {
      gap: 80px;
    }
  }
}

.hero {
  &__title {
    font-size: 120px;
    font-weight: 800;
    line-height: 144px;
    text-align: left;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  &__subtitle {
    font-size: 24px;
    font-weight: 600;
    line-height: 32.74px;
    text-align: center;
    text-underline-position: from-font;
    text-decoration-skip-ink: none;
  }

  &__btnContainer {
    display: flex;
    align-items: center;
    gap: 24px;
  }
}