@media (max-width: 945px) {
  .Slot-battles {
    .Battle {
      max-width: calc(100% - 40px) !important;
      margin: 50px 0 0 -30px !important;

      .Battle-header h3 {
        padding: 18px 0 0 40px !important;
      }
    }
  }

  .Active-filter-slot-battles div {
    padding: 0 15px !important;
    font-size: 12px !important;
  }

  .Battle-Wagers-visible-content-visible {
    width: 100% !important;
    height: 60% !important;
    top: calc(50% - 175px) !important;
    box-shadow:
      0 2px 1px -1px #0003,
      0 4px 4px 0 #00000024,
      -10px -14px 7px 0 #0000001f !important;
    //top: 0 !important;
  }
  .App-contents-container .Margined-Heading-1 {
    h4 {
      text-align: left !important;
      padding-top: 10px !important;
    }
  }
}

@media (max-width: 725px) {
  .Wagers {
    position: absolute;
  }

  .Battles-contents-slips {
    width: 100%;
    padding: 10px;
  }

  .Battle-Wagers-visible {
    width: 100%;
    height: 100%;
    border: none !important;
    background: none !important;
    position: absolute;
  }

  .Wagers-buttons {
    padding-top: 40px;
  }

  .Wagers-slots {
    height: calc(100% - 350px) !important;
  }
  .Battle-Wagers {
    .Wagers-slot-count {
      width: calc(100% - 0px) !important;
      margin-left: 0 !important;
    }

    .Wagers-input input {
      width: calc(100% - 30px);
    }
  }
  .Wagers-bet-options {
    display: inline-flex;
    width: 100%;

    .Wagers-slots {
      width: 50% !important;
    }

    .Wagers-bet-options-content {
      width: 50% !important;
      margin-right: 10px;
    }

    .Wagers-slots .Wager-slot {
      width: 200px !important;
      height: 180px !important;
    }

    .Wagers-payout-rates {
      display: flex !important;
      flex-direction: column;

      ul {
        width: 100% !important;

        padding: 0 !important;
      }
    }
  }
}

@media (max-width: 700px) {
  .Battles-contents {
    padding-left: 0;
    padding-right: 0;
  }
}
