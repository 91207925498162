/* FileUploader.module.scss */
.file-uploader {
  &__details {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }

  &__image {
    position: relative;
    // max-width: 350px;
    // max-height: 200px;
    width: auto;
    height: 200px;
    border-radius: 10px;
    align-self: center;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      object-fit: cover;
    }
  }

  // &__image {
  //   position: relative;
  //   max-width: 500px;
  //   height: 100%;
  //   max-height: 200px;
  //   aspect-ratio: 16 / 9;
  //   border-radius: 10px;
  //   align-self: center;
  //   overflow: hidden;

  //   img {
  //     width: 100%;
  //     height: 100%;
  //     object-fit: contain;
  //     border-radius: 10px;
  //   }
  // }

  &__delete {
    position: absolute;
    top: 6px;
    right: 6px;
    padding: 4px;
    background: #202431;
  }

  &__label {
    font-size: 18px;
    font-weight: 600;
    line-height: 24.55px;
    text-align: left;
  }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__file {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__icon {
    width: 16px;
    height: 16px;
  }

  &__trash-icon {
    width: 20px;
    height: 20px;
  }

  &__dropzone {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 160px;
    border: 1px solid #202431;
    border-radius: 10px;
    background-color: #20243180;
    padding: 16px;
    text-align: center;
  }

  &__title {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: center;
    color: white;
  }

  &__subtitle {
    font-size: 18px;
    font-weight: 500;
    line-height: 24.55px;
    text-align: center;
    color: #ffffff80;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    cursor: pointer;
  }
}
